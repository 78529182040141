export type costSettings = {
    schoolsClosed: boolean,
    restaurantsClosed: boolean,
    amountOfTesting: number,
    healthSpend: number
}


export function calculateCost(settings: costSettings) {
	const {
		schoolsClosed,
		restaurantsClosed,
		amountOfTesting,
		healthSpend
	} = settings;

	return healthSpend * 100
        + amountOfTesting * 3
        + (schoolsClosed ? 100 : 0)
        + (restaurantsClosed ? 100 : 0);
}

export default calculateCost;
