import Dictionary from '../interfaces/dictionary';

function loadImage(path: string, timeout: number = 2000): Promise<HTMLImageElement> {
	return new Promise((resolve, reject) => {
		const image = new Image();
		const timer = setTimeout(() => reject(`Timeout loading image ${path}`), timeout);
		image.onload = () => {
			clearTimeout(timer);
			resolve(image);
		};
		image.src = path;
	});
}

async function loadImages(images: Dictionary<string>): Promise<Dictionary<HTMLImageElement>> {

	const loadedImages: Dictionary<HTMLImageElement> = {};
	console.log('Load Image', images);

	for(const key in images) {
		const image = await loadImage(images[key]);
		loadedImages[key] = image;
	}

	return loadedImages;
}

export default loadImages;

export {
	loadImage,
	loadImages
};
