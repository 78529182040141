interface VirusButtonOptions{
	coronaButton: string,
	gastroButton: string,
	respiratoryButton: string,
	onSelectVirus: (virus: string) => any;
}

function setupVirusSelector(options: VirusButtonOptions): void {
	console.log({options});


    const buttonPressed = (evt: Event) => {
        console.log('clicked');
        evt.preventDefault();
        const target = evt.currentTarget as HTMLElement;
        const type: string = target?.dataset?.virus || '';
        console.log({evt, type});

        // Replce old overlay with a new one
        const oldOverlay = target.closest('#setupOverlay');
        if(oldOverlay) {
            const parent = oldOverlay.parentNode;
            oldOverlay.id = ''; // clear id to allow for reuse
            parent?.removeChild(oldOverlay);
        }

        options.onSelectVirus(type);
    }



	const coronaButton = document.querySelector(options.coronaButton) as HTMLElement;
	console.log({btn: 'corona', coronaButton});
	if(coronaButton) {
		coronaButton.dataset.virus = 'corona';
		coronaButton.addEventListener('click', buttonPressed);
	}
	const gastroButton = document.querySelector(options.gastroButton) as HTMLElement;
	if(gastroButton) {
		gastroButton.dataset.virus = 'gastro';
		gastroButton.addEventListener('click', buttonPressed);
	}
	const respiratoryButton = document.querySelector(options.respiratoryButton) as HTMLElement;
	if(respiratoryButton) {
		respiratoryButton.dataset.virus = 'respiratory';
		respiratoryButton.addEventListener('click', buttonPressed);
	}

}

export default setupVirusSelector;
