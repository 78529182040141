import Dictionary from '../interfaces/dictionary';
import Zone from '../interfaces/zone';

const zones: Dictionary<Zone> = {
	left: {
		title: 'left',
		endX: 530
	},
	right: {
		title: 'right',
		startX: 540
	},
	border: {
		title: 'border',
		startX: 534,
		endX: 536
	}
};

/**
 * Get a zone with all values set based on the plane dimensions
 * @param {string} zoneTitle The zone title to fetch
 * @param {Plane} plane The plane to use the dimensions from when completing the zone
 * @return {Zone} The completed zone
 **/
function getZone(zoneTitle: string, plane: Plane): Zone {
	const baseZone = zones[zoneTitle];
	if(!baseZone) {
		return null;
	}

	const newZone: Zone = {
		title: baseZone.title,
		startX: baseZone.startX || 0,
		endX: baseZone.endX || plane.width,
		startY: baseZone.startY || 0,
		endY: baseZone.endY || plane.height
	};
	return newZone;
}

export default getZone;
export {
	zones,
	getZone
};
