import {PersonStatus, PersonAgent} from './person_agent';
import logger from 'loglevel';

const ACTIVE_INFECTION_COUNT_ATTRIBUTE = 'infectionCount';
const getZoneInfectionCountAttribute = (zone: string) => `${zone}TotalInfections`;

const log = logger.getLogger('PersonAgent');
log.setLevel('WARN');

class Virus {
	host: PersonAgent;
	infectionStage: number;
	mortalityRate: number;
	infectionChance: number;

	constructor(host: PersonAgent) {

		this.host = host;


		host.virus = this;

		// These values should be overriden by implementations
		this.infectionStage = 0;
		this.mortalityRate = 0;
		this.infectionChance = 0;

		this.incrementInfectionCount();
	}

	incrementInfectionCount() {
		const {plane, cell} = this.host;

		// keep track of the total number of active infections so we can stop if the virus dies out
		const infections = plane.getAttribute(ACTIVE_INFECTION_COUNT_ATTRIBUTE) || 0;
		plane.setAttribute(ACTIVE_INFECTION_COUNT_ATTRIBUTE, parseInt(infections, 10) + 1);

		// Keep track of the number of infections that occur in a zone for scoring
		let zone;
		if(plane.cellHasZone(cell, 'left')) {
			zone = 'z1';
		} else if(plane.cellHasZone(cell, 'right')) {
			zone = 'z2';
		}
		if(zone) {
			const zoneAttribute = getZoneInfectionCountAttribute(zone);
			const zoneInfections = plane.getAttribute(zoneAttribute) || 0;
			plane.setAttribute(zoneAttribute, parseInt(zoneInfections, 10) + 1);
		}
	}

	decrementInfectionCount() {
		const infections = this.host.plane.getAttribute(ACTIVE_INFECTION_COUNT_ATTRIBUTE) || 1;
		this.host.plane.setAttribute(ACTIVE_INFECTION_COUNT_ATTRIBUTE, parseInt(infections, 10) - 1);
	}

	static infect(host: PersonAgent): Virus {
		return new Virus(host);
	}

	// Instances should implement this to create their own type
	infectNew(host: PersonAgent): Virus {
		return Virus.infect(host);
	}

	infectCautious(): undefined {
		// Cautious people are only exposed on the same cell
		this.host.cell
			.getAgents()
			.filter((exposedAgent) => (!exposedAgent.virus && exposedAgent instanceof PersonAgent && exposedAgent.status === PersonStatus.cautious))
			.forEach((exposedPerson) => {
				//console.log('Person is directly exposed', exposedPerson);
				if((Math.random() * 100) < this.infectionChance) {
					this.infectNew(exposedPerson);
				}
			});
	}

	infectIndifferent(): undefined {
		const {cell} = this.host;
		// Indifferent people are exposed on any neighbouring cell
		const infectionZone = this.host.plane
			.getCellsInRange(cell.x -1, cell.x + 1, cell.y - 1, cell.y + 1)
			.flatMap((exposedCell) => exposedCell.getAgents())
			.filter((exposedAgent) => (!exposedAgent.virus && exposedAgent instanceof PersonAgent && exposedAgent.status === PersonStatus.indifferent))
			.forEach((exposedPerson) => {
				if((Math.random() * 20) < this.infectionChance) {
					this.infectNew(exposedPerson);
				}
			});
	}

	run(): void {
		this.infectionStage--;
		if(this.infectionStage === 0) {
			this.host.virus = null;
			this.decrementInfectionCount();
		} else {

			this.infectCautious();

			this.infectIndifferent();



			if(!this.checkHealth()) {
				this.host.die();
				this.decrementInfectionCount();
			}
		}
	}

	checkHealth(): boolean {
		if(this.infectionStage < 2 && (Math.random() * 100) < this.mortalityRate) {
			return false;
		}
		return true;
	}
}

export default Virus;
