import {Vector2} from 'three';
import type Plane from './plane';
import type Cell from './cell';

export interface AgentSettings {
    tags?: string[];
}

/**
 * Base agent class
 **/
class Agent {
	static type = 'Default';

	plane: Plane;
	settings: AgentSettings;
	heading: Vector2;
	type: string;
	tags: string[];
	cell: Cell | undefined;


	/**
	 * Constructor
	 * @param {Plane} plane The plane this agent is running on
	 * @param {Cell} cell The cell this agent is attached to
	 * @param {object} config Configuration for this agent
	 **/
	constructor(plane: Plane,  settings: AgentSettings = {}) {
		this.plane = plane;
		this.settings = settings;
		this.heading = new Vector2(0, 1); // Direction vector
		this.type = this.constructor.name || Agent.type;
		this.tags = [];

		if(settings.tags) {
			settings.tags.forEach((tag) => this.addTag(tag));
		}
	}

	/**
	 * Attach this agent to a new cell
	 * @param {Cell} cell Cell to attach the agent to
	 * @return {undefined} No return value
	 **/
	setCell(cell: Cell): void{
		this.cell = cell;
	}

	/**
	 * Get the cell in front of the agent given the bearing
	 * Note that if the bearing is not a unit vector (longer than 1)
	 * it assumes the agent skips any cells before and only looks the target distance away
	 * @return {Cell} The cell at the given bearing or null if it looks off the edge
	 **/
	getCellInFront(): Cell | null {
	    if(!this.cell) {
	        throw new Error('Agent.getCellInFront: Agent is not on a cell');
        }
		return this.plane.getCellInDirection(this.cell.x, this.cell.y, this.heading);
	}

	/**
	 * Move the agent along the given bearing.
	 * If no direction is provided it assumes the agent moves on their existing heading
	 * @param {Vector2} direction The direction to move the agent. Defaults to existing heading
	 * @return {Cell | null} The new cell for the agent or null if the move failed
	 **/
	move(direction?: Vector2): Cell | null {
	    if(!this.cell) {
	        throw new Error('Agent.move: Agent is not on a cell');
        }
	        
		const bearing = direction || this.heading;
		const newCell = this.plane.getCellInDirection(this.cell.x, this.cell.y, bearing);
		if(!newCell) {
			return null;
		}
		this.plane.removeAgentFromCell(this, this.cell);
		this.plane.addAgentToCell(this, newCell);
		return newCell;
	}

	/**
	 * Rotate the current direction the agent is facing
	 * @param {number} angle The angle of rotation in radians
	 * @return {Vector2} The new heading agent is facing
	 **/
	rotate(angle: number): Vector2 {
		this.heading.rotateAround(new Vector2(0, 0), angle);
		return this.heading;
	}

	/**
	 * Rotate the agent to face the target cell
	 * @param {Cell} The cell to face
	 * @return {Vector2} The new heading agent is facing
	 **/
	faceCell(targetCell: Cell): Vector2 {
	    if(!this.cell) {
	        throw new Error('Agent.faceCell: Agent is not on a cell');
        }
		const direction = this.plane.getDirectionTo(this.cell, targetCell);
		this.heading = direction.clampLength(1, 1);
		return this.heading;
	}

	/**
	 * Save this agent against a tag in the plane
	 * @param {string} tag The tag to save against the agent
	 * @return {Agent} This agent
	 **/
	addTag(tag: string): Agent {
		return this.plane.tagAgent(this, tag);
	}

	/**
	 * Remove the agent from a tag on the plane
	 * @param {string} tag The tag to remove from this agent
	 * @return {Agent} This agent
	 **/
	removeTag(tag: string): Agent {
		return this.plane.untagAgent(this, tag);
	}

	/**
	 * Check if an agent has a tag
	 * @param {string} tag The tag to check for
	 * @return {boolean} True if agent has the tag, otherwise false
	 **/
	hasTag(tag: string): boolean {
		return this.tags.indexOf(tag) >= 0;
	}


	/**
	 * Entry point for this agent. Should be run on each tick.
	 * @return {undefined} no return value
	 **/
	run(): void {
	    if(!this.cell) {
	        throw new Error('Agent.run: Agent is not on a cell');
        }
		if(this.cell.getAgents().length > 1) {
			console.log('Cell has more than one agent', this.cell);
		}
	}


}

export default Agent;
