import {PersonStatus, PersonAgent} from './person_agent';
import logger from 'loglevel';
import Virus from './virus';

const log = logger.getLogger('PersonAgent');
log.setLevel('WARN');

class CoronaVirus extends Virus {

	constructor(host: PersonAgent) {
		super(host);

		console.log('Coronavirus');

		this.infectionStage = 600 + Math.round(Math.random() * 200);

		this.infectionChance = 4;
		if(host.age < 10) {
			this.mortalityRate = 1;
		} else if(host.age < 65) {
			this.mortalityRate = 50;
		} else {
			this.mortalityRate = 100;
		}

		// Reduce mortality rate by spending on health services (similar to gastro)
		if(host.attributes) {
			const healthSpend = parseInt(host.attributes.healthSpend, 10);
			log.debug({attributes: host.attributes, healthSpend, mortalityRate: this.mortalityRate, newRate: this.mortalityRate / healthSpend});
			if(healthSpend) {
				this.mortalityRate = this.mortalityRate / healthSpend;
			}
		}

	}


	static infect(host: PersonAgent): Virus {
		if(host?.icons?.corona) {
			host.icons.infectious = host.icons.corona;
		}
		return new CoronaVirus(host);
	}

	infectNew(host: PersonAgent): Virus {
		return CoronaVirus.infect(host);
	}
}

export default CoronaVirus;
