
/**
 * Draw the current time on the page
 * @param {DOMElement} textElement The element to write the time in
 * @param {Plane} plane The plane to read ticks from
 * @return {undefined} no return value
 **/
function setupCounter(textElement, plane) {

	const updateTick = () => {
		if(plane.tick > 0) {
		    const days = Math.floor(plane.tick / 24);
		    const hours = plane.tick % 24;
            textElement.textContent = `${days ? `${days} days ` : ''} ${hours} hours`;
		}
		window.requestAnimationFrame(updateTick);
	};
	window.requestAnimationFrame(updateTick);
}

export default setupCounter;
