import setupVirusSelector from './virus-selector.ts';
import gastroTemplate from '../../views/virus-instructions-gastro.pug';
import coronavirusTemplate from '../../views/virus-instructions-coronavirus.pug';
import respiratoryTemplate from '../../views/virus-instructions-respiratory.pug';
import setupControls from './setup-controls.ts';
import setupTemplate from '../../views/setup.pug';


const informationTemplates: {[key: string]: () => string} = {
    gastro: gastroTemplate,
    corona: coronavirusTemplate,
    respiratory: respiratoryTemplate
};

function appendHTMLToElement(target: Element, htmlString: string): Element {
    const container = document.createElement('div');
	container.innerHTML = htmlString;
	const newElement = container.firstChild as Element;
	if(!newElement) {
	    throw new Error(`Failed to create element from html ${htmlString}`);
    }
    target.appendChild(newElement);
    return newElement;

    /* This could be just two lines but we don't get the guranteed return
        target.insertAdjacentHTML('beforeend', setupTemplate());
        return target.lastChild as HTMLElement | null;
    */
}

function showVirusDetails(virus: string, nextStep: () => void) {
    const PAGE_CLASS = 'virus__overlay__page';


    if(!informationTemplates[virus]) {
        throw new Error(`Invalid virus selected ${virus}`);
    }

    const htmlString = informationTemplates[virus]();

    const targetContainer = document.querySelector('.map__container#mapContainer') || document.body;
    const instructions = appendHTMLToElement(targetContainer, htmlString);
	const destroy = () => targetContainer.removeChild(instructions);

    const nextBtnPressed = (evt:Event) => {
        evt.preventDefault();

        // Get and hide the current page
        const target = evt.target as Element | undefined;
        const thisPage = target?.closest(`.${PAGE_CLASS}`);
        if(!thisPage) {
            // If we can't find this page call the next step and exit
            destroy();
            nextStep();
            return;
        }
        let tmpElem = thisPage;

        // Now find the next page if it exists
        let nextPage: Element|undefined = undefined;
        while(!nextPage && tmpElem.nextSibling) {
            tmpElem = tmpElem.nextSibling as Element;
            if(tmpElem.classList.contains(PAGE_CLASS)) {
                nextPage = tmpElem as Element;
            }
        }

        if(nextPage) {
            thisPage.parentNode?.removeChild(thisPage);
            nextPage.classList.remove('hidden');
            return;
        }

        // If there are no pages left call the next step
        destroy();
        nextStep();
    };

    instructions
        .querySelectorAll('.virus__overlay__next-btn')
        .forEach((btn) => {
            btn.addEventListener('click', nextBtnPressed);
        });
}





export function showSetupPages() {

    let virus:string|undefined = undefined;
    let currentStep = 0;

    const nextStep = (evt?: Event) => {
        console.log(`showSetupPages: show step ${currentStep}`);
        if(evt) {
            evt.preventDefault();
        }
        steps[currentStep++]();
    }

    const steps: (() => any)[] = [
        () => setupVirusSelector({
            // It would be nice to move the template into here too
            coronaButton: '#coronaVirus',
            gastroButton: '#gastroVirus',
            respiratoryButton: '#respiratoryVirus',
            replaceElement: '#setupOverlay',
            onSelectVirus: (selectedVirus: string) => {
                virus = selectedVirus;
                nextStep();
            }
        }),
        () => showVirusDetails(virus || '', nextStep),
        () => {
            const targetContainer = document.querySelector('.map__container#mapContainer') || document.body;
            const html = setupTemplate();
            appendHTMLToElement(targetContainer, html);
            setupControls(virus);
        }
    ];

    nextStep();

}
