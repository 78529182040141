import type Plane from '../plane';
import calculateCost from '../util/calculate-cost';
import {calculateScore} from '../util/calculate-score';
import {SetupPlaneOptions} from '../controls/setup-controls';
import {hideSlider} from '../util/details-slider';
import endgameTemplate from '../../views/endgame.pug';

const INFECTION_COUNT_ATTRIBUTE = 'infectionCount';
const Z1_INFECTIONS = 'z1TotalInfections';
const Z2_INFECTIONS = 'z2rightTotalInfections';
const Z1_DEATHS = 'z1Deaths';
const Z2_DEATHS = 'z2Deaths';
const OPTIONS_ATTRIBUTE = 'setupOptions';

const MAX_TICKS = 2000;
const HEALTH_SPEND_LABELS = ['Low', 'Medium Low', 'Medium High', 'High'];

interface GameStats extends SetupPlaneOptions {
	zone1Infections: number,
	zone1TotalInfections: number,
	zone1NewInfections: number,
	zone1Deaths: number,
	zone1Cost: number,
	zone1Score: number,
    zone1Schools: string,
    zone1Restaurants: string,
    zone1VirusPctString: string,
    zone1TestingString: string,
    zone1HealthSpendString: string,
	zone2Infections: number,
	zone2TotalInfections: number,
	zone2NewInfections: number,
	zone2Deaths: number,
	zone2Cost: number,
	zone2Score: number,
    zone2Schools: string,
    zone2Restaurants: string,
    zone2VirusPctString: string,
    zone2TestingString: string,
    zone2HealthSpendString: string
}



const getOpenText = (status: boolean): string => status ? 'Closed' : 'Open';

function getStats(plane: Plane): GameStats {
	let setupOptions: SetupPlaneOptions;
	let zone1Cost = 0;
	let zone2Cost = 0;

	try {
		setupOptions = JSON.parse(plane.getAttribute(OPTIONS_ATTRIBUTE)) as SetupPlaneOptions;
		zone1Cost = calculateCost({
			schoolsClosed: setupOptions.zone1School,
			restaurantsClosed: setupOptions.zone1Resto,
			amountOfTesting: setupOptions.zone1Testing,
			healthSpend: setupOptions.zone1HealthSpend
		});
		zone2Cost = calculateCost({
			schoolsClosed: setupOptions.zone2School,
			restaurantsClosed: setupOptions.zone2Resto,
			amountOfTesting: setupOptions.zone2Testing,
			healthSpend: setupOptions.zone2HealthSpend
		});
	} catch (err: unknown) {
		const msg = `Invalid setup options for attribute ${OPTIONS_ATTRIBUTE}: ${plane.getAttribute(OPTIONS_ATTRIBUTE)}`;
		console.error(msg);
		console.error(err);
		throw new Error(msg);
	}
	console.log({setupOptions});
	const zone1Infections = parseInt(plane.getAttribute(Z1_INFECTIONS) || '0', 10);
    const zone2Infections = parseInt(plane.getAttribute(Z2_INFECTIONS) || '0', 10);
    const zone1Deaths = parseInt(plane.getAttribute(Z1_DEATHS) || '0', 10);
    const zone2Deaths = parseInt(plane.getAttribute(Z2_DEATHS) || '0', 20);
    const zone1Score = calculateScore({population: setupOptions.zone1People, deaths: zone1Deaths, cost: zone1Cost});
    const zone2Score = calculateScore({population: setupOptions.zone2People, deaths: zone2Deaths, cost: zone2Cost});
	return {
		...setupOptions,
		zone1Infections,
		zone1NewInfections: zone1Infections,
		zone1TotalInfections: zone1Infections + setupOptions.zone1Infected,
		zone1Deaths,
		zone1Cost,
		zone1Score,
		zone1Schools: getOpenText(setupOptions.zone1School),
		zone1Restaurants: getOpenText(setupOptions.zone1Resto),
		zone1VirusPctString: `${setupOptions.zone1Infected}%`,
		zone1TestingString: `${setupOptions.zone1Testing}%`,
		zone1HealthSpendString: HEALTH_SPEND_LABELS[setupOptions.zone1HealthSpend] || 'none',
		zone2Infections,
		zone2NewInfections: zone2Infections,
		zone2TotalInfections: zone2Infections + setupOptions.zone2Infected,
		zone2Deaths,
		zone2Cost,
		zone2Score,
		zone2Schools: getOpenText(setupOptions.zone2School),
		zone2Restaurants: getOpenText(setupOptions.zone2Resto),
		zone2VirusPctString: `${setupOptions.zone2Infected}%`,
		zone2TestingString: `${setupOptions.zone2Testing}%`,
		zone2HealthSpendString: HEALTH_SPEND_LABELS[setupOptions.zone2HealthSpend] || 'none'
	};
}

export default function checkForEndgame(evt: Event): void {
    if(!evt.target) {
        throw new Error('No plane available for endgame');
    }
	const plane: Plane = evt.target;
	const tick = plane.tick;
	const infections = plane.getAttribute(INFECTION_COUNT_ATTRIBUTE);
	if(!infections || tick >= MAX_TICKS) {
		plane.stop();
		hideSlider();
		const container = document.querySelector('#mapContainer');
		if(!container) {
			console.error('Cannot show stats as container is not found');
			return;
		}
		const stats =  getStats(plane);
		const html = endgameTemplate(stats);
		container.insertAdjacentHTML('beforeend', html);
		Object.keys(stats).forEach((stat) => {
			const statElem = document.getElementById(stat);
			if(statElem) {
				statElem.innerText = stats[stat] as string;
			}
		});

		const restartBtn = document.getElementById('restartBtn');
		if(restartBtn) {
		    restartBtn.addEventListener('click', () => {
		        console.log('reload');
		        window.location.reload();
            });
        }

		console.log(stats);
	}

}
