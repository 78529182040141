
type LogMessage = {
    level: number,
    message: string
};

class Logger {
    logLevel: number;
    messages: LogMessage[];
    messageLimit: number;
    logTarget?: HTMLElement;


    static ERROR = 0;
    static WARN = 1;
    static INFO = 2;




    constructor(level = 10) {
        this.logLevel = level;
        this.messages = [];
        this.messageLimit = 200;
    }

    log(level: number, message: string) {
        if(level <= this.logLevel) {
        if(this.messages.length >= this.messageLimit) {
            this.messages.shift();
        }
        this.messages.push({
            level,
            message
        });
        }
    }

    updateLog() {
        requestAnimationFrame(() => {
        if(this.logTarget) {
            const messageText = this.messages.map((message) => {
            let className = "logger--info";
            if(message.level === Logger.ERROR) {
                className = "logger--error";
            } else if(message.level === Logger.WARN) {
                className = 'logger--warn';
            }
            return `<p class="${className}>${message.message}</p>`;
            });
            this.logTarget.innerHTML = messageText.join('');
            this.updateLog();
        }
        });
    }

    displayLog(targetElement: HTMLElement) {
        this.logTarget = targetElement;
        this.updateLog();
    }

    cancelDisplay() {
        this.logTarget = undefined;
    }

}
const loggerInstance = new Logger();

function getLogger() {
  return loggerInstance;
}

export default getLogger;

export {Logger};
