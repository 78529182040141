import config from '../config';

const activeClass = '.reload-btn--active';

function loadData(plane) {

	// Load image
	const background = new Image();
	background.onload = () => {
		plane.loadImage(background, {layer: 'background', scaleToCanvas: true});
	};
	background.src = config.backgroundImage;

	// This is almost certainly not required but there is not nice setup that happens in loadData
	const planeData = new Array(1077);
	planeData.fill(new Array(698).fill(0));
	plane.loadData(planeData);

}

export default loadData;
