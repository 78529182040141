import Agent from './agent';
import Virus from './virus.ts';
import { v4 as uuidv4 } from 'uuid';
import {loadImage} from './lib/load_images.ts';
import Dictionary from './interfaces/dictionary';
import logger from 'loglevel';
import type LocationAgent from './location_agent';
import type Plane from './plane';
import type Cell from './cell';
import type {AgentSettings} from './agent';
import type Zone from './zone';

const log = logger.getLogger('PersonAgent');
log.setLevel('TRACE');
log.enableAll();

enum PersonStatus {
	indifferent = 'indifferent',
	careful = 'careful',
	infectious = 'infectious',
	isolation = 'isolation',
	dead = 'dead'
}

export type BorderCrossing = {
    probability: number,
    zone: Zone,
    home: Zone,
    number: number
};

const travellingTag = 'travelling';
const returningTag = 'returning';
const restaurantTag = 'restaurant';


// Calculate direction with Math.round(Vector2.angle() / (Math.PI / 4))
// Unfortunately north can be 0 or 8 so EE is included as an extra value
enum Direction {
	E = 0,
	SE = 1,
	S = 2,
	SW = 3,
	W = 4,
	NW = 5,
	N = 6,
	NE = 7,
	EE = 8
}


const isolationRadius: number = 3;

interface Settings extends AgentSettings {
	images: HTMLImageElement[] | string[],
	icons: Dictionary<HTMLImageElement>,
	markers: Dictionary<HTMLImageElement | string>,
	attributes: Dictionary<unknown>,
	age: number,
	faceMask?: boolean
}

class PersonAgent extends Agent {
	static type = 'PersonAgent';

	type: string;
	images: Dictionary<HTMLImageElement | string>;
	icons: Dictionary<HTMLImageElement | string>; // Icons appear above the person
	markers: Dictionary<HTMLImageElement | string>; // Markers appear behind the person
	image?: HTMLImageElement | string;
	icon?: HTMLImageElement | string;
	marker?: HTMLImageElement | string;
	personId: string;
	goingToGathering?: LocationAgent;
	_status?: PersonStatus;
	age: number;
	borderJumper: boolean;
	_virus: Virus;
	testingRate: number;
	attributes: Dictionary<unknown>;
	faceMask: boolean;


	/**
	 * Constructor
	 * @param {Plane} plane The plane this agent is running on
	 * @param {Cell} cell The cell this agent is attached to
	 * @param {object} config Configuration for this agent
	 **/
	constructor(plane: Plane, settings: Settings) {
		super(plane, settings);
		this.type = PersonAgent.type;
		this.borderJumper = false;
		this.attributes = settings.attributes || {};
		this.testingRate = parseInt(settings?.attributes?.testing as string, 10) || 0;

		this.images = {};
		for(const imageKey in settings.images) {
			if(typeof this.images[imageKey] === 'string') {
				loadImage(this.images[imageKey])
					.then((image: HTMLImageElement) => {
						this.images[imageKey] = image;
					})
					.catch((err: unknown) => {
						log.error(err);
					});
			} else {
				this.images[imageKey] = settings.images[imageKey];
			}
		}

		this.icons = {};
		for(const imageKey in settings.icons) {
			if(typeof this.icons[imageKey] === 'string') {
				loadImage(this.icons[imageKey])
					.then((image: HTMLImageElement) => {
						this.icons[imageKey] = image;
					})
					.catch((err: unknown) => {
						log.error(err);
					});
			} else {
				this.icons[imageKey] = settings.icons[imageKey];
			}
		}

		this.markers = {};
		for(const imageKey in settings.markers) {
			if(typeof this.markers[imageKey] === 'string') {
				loadImage(this.markers[imageKey])
					.then((image: HTMLImageElement) => {
						this.markers[imageKey] = image;
					})
					.catch((err: unknown) => {
						log.error(err);
					});
			} else {
				this.markers[imageKey] = settings.markers[imageKey];
			}
		}

		this.personId = uuidv4();
		this.goingToGathering = undefined;
		this.status = PersonStatus.indifferent;
		this.age = settings.age || Math.floor(Math.random() * 80);
		this.faceMask = settings.faceMask || false;
		this._virus = null;
	}

	set virus(aVirus: Virus) {
		this._virus = aVirus;
		if(aVirus) {
			this.status = PersonStatus.infectious;
			if(this.testingRate) {
				if(Math.random() * 100 < this.testingRate) {
					this.status = PersonStatus.isolation;
				}
			}
		} else {
			this.status = PersonStatus.careful;
		}
	}

	get virus(): Virus {
		return this._virus;
	}


	set status(value: PersonStatus | undefined) {
		if(!value || value == this._status) {
			return;
		}
		this._status = value;
		this.icon = this.icons[value];
		this.marker = this.markers [value];

		// Handle isolation side effects
		if(this.cell && value === PersonStatus.isolation) {
			const isolationArea: Cell[] = this.plane.getCellsInRange(this.cell.x-isolationRadius, this.cell.x+isolationRadius, this.cell.y-isolationRadius, this.cell.y+isolationRadius);
			isolationArea.forEach((cell: Cell) => {
				const count = cell.getProp('isolation') as number || 0;
				cell.setProp('isolation', count+1);
			});
		} else if(this.cell && this._status === PersonStatus.isolation) {
			const isolationArea: Cell[] = this.plane.getCellsInRange(this.cell.x-isolationRadius, this.cell.x+isolationRadius, this.cell.y-isolationRadius, this.cell.y+isolationRadius);
			isolationArea.forEach((cell: Cell) => {
				const count = cell.getProp('isolation') as number || 0;
				cell.setProp('isolation', count-1);
			});
		}
	}

	get status(): PersonStatus | undefined {
		return this._status;
	}

	/**
	 * Tell this person to go to a gathering (if they are not infected)
	 * @param {LocationAgent} location The gathering location
	 **/
	goToGathering(gatheringLocation: LocationAgent): void {
		this.goingToGathering = gatheringLocation;
	}

	die(): void{
		this.status = PersonStatus.dead;

		// Record the death for scorekeeping

		// Keep track of the number of infections that occur in a zone for scoring
		let zone;
		if(this.cell && this.plane.cellHasZone(this.cell, 'left')) {
			zone = 'z1';
		} else if(this.cell && this.plane.cellHasZone(this.cell, 'right')) {
			zone = 'z2';
		}
		if(zone) {
			const zoneAttribute = `${zone}Deaths`;
			const deaths = this.plane.getAttribute(zoneAttribute);
			this.plane.setAttribute(zoneAttribute, String((parseInt(deaths, 10) || 0) + 1));
		}

	}

	/**
	 * Entry point for this agent. Should be run on each tick.
	 * @return {undefined} no return value
	 **/
	run(): undefined {
	    // TODO: Monitor performance
		const startCell = this.cell;
		if(!startCell) {
		    // Nothing to do if we are not on a cell
		    return;
        }

		if(this.goingToGathering?.cell && this.goingToGathering && this.status !== PersonStatus.isolation) {
			this.plane.removeAgentFromCell(this, startCell);
			this.plane.addAgentToCell(this, this.goingToGathering.cell);
			this.goingToGathering = undefined;
		} else if(this.status !== PersonStatus.isolation) {
			// Just walking around


			// If there are no cells in front turn around and walk forward
			// Again if there are no cells in front turn slightly right and walk forward
			// If there is a nogo in front turn around and walk forward
			// Then rotate a random little bit right, a random little bit left then walk forward
			let inFront = this.getCellInFront();

			if(!inFront) {
				this.rotate(Math.PI);
				this.move();
				inFront = this.getCellInFront();
			}
			if(!inFront) {
				this.rotate(Math.PI / 4);
				this.move();
				inFront = this.getCellInFront();
			}
			if(inFront && inFront.getProp('nogo')) {
				this.rotate(Math.PI);
				this.move();
				inFront = this.getCellInFront();
			}
			//if(inFront && plane.cellHasZone(inFront, 'border') && !this.borderJumper) {
			if(inFront && inFront.getProp('border') && !this.borderJumper) {
				this.rotate(Math.PI);
				this.move();
				inFront = this.getCellInFront();
			}
			// Turn away from an isolation zone unless are are already inside one
			if(this.cell && !this.cell.getProp('isolation') && inFront && inFront.getProp('isolation')) {
				this.rotate(Math.PI);
				this.move();
				inFront = this.getCellInFront();
			}

            // TODO: Cant these next two steps be replaced with this.rotate((Math.PI / 5) - (Math.random() * Math.PI / 2.5))?
			this.rotate(Math.random() * (Math.PI / 5));
			this.rotate(-(Math.random() * (Math.PI / 5)));
			this.move();
		}

		// Border jumping
		if(this.borderJumper) {
			const travelling = this.hasTag(travellingTag);
			const returning = this.hasTag(returningTag);
			const borderCrossing = this.attributes?.borderCrossing as BorderCrossing;
			if(borderCrossing && borderCrossing?.number && !travelling && !returning) {
				//logger.debug(`Check crossing status of ${this.personId}`);
				if(Math.floor(Math.random() * 100 * borderCrossing.probability) === 0) { // This should be a 1% chance each turn of anyone crossing before slots are exhaused
					this.addTag(travellingTag);
					logger.debug(`Person ${this.personId} is travelling from ${borderCrossing?.home?.title} to ${borderCrossing?.zone?.title}`);
					console.log(`Person ${this.personId} is travelling from ${borderCrossing?.home?.title} to ${borderCrossing?.zone?.title}`);
				}
			} else if(borderCrossing && travelling) {
				if(Math.random() <= 0.5) {
					const targetResto = this.plane.getAgentsWithTag(restaurantTag).find((resto) => resto.hasTag(borderCrossing?.zone?.title)) as LocationAgent;
					if(targetResto && targetResto.cell) {
                        this.faceCell(targetResto.cell);
                        logger.debug(`Person ${this.personId} is walking towards ${targetResto.locationId} in zone $borderCrossing?.zone?.title}`);
                    }
				}
				const atLocation = this?.cell?.getProp('location') as LocationAgent;
				if(atLocation) {
					if(atLocation.hasTag(restaurantTag) && atLocation.hasTag(borderCrossing?.zone?.title)) {
						this.removeTag(travellingTag);
						this.addTag(returningTag);
						const border: Cell[] = this.plane.getCellsInZone('border');
						const dest = border[Math.floor(Math.random() * border.length)];
						this.faceCell(dest);

						logger.debug(`Person ${this.personId} has been to ${atLocation.locationId} in zone ${borderCrossing?.zone?.title} and is ready to go home`);
						console.log(`Person ${this.personId} has been to ${atLocation.locationId} in zone ${borderCrossing?.zone?.title} and is ready to go home`);
					}
				}
			} else if(this.cell && borderCrossing && returning) {
				const returned = this.plane.cellHasZone(this.cell, borderCrossing?.home?.title);
				//const returned = this.cell.getProp(this.attributes.borderCrossing?.home?.title);
				if(returned) {
					console.log({returned});
					this.removeTag(returningTag);
					this.borderJumper = false;

					logger.debug(`Person ${this.personId} has returned home to ${borderCrossing?.home?.title} from ${borderCrossing?.zone?.title}`);
					console.log(`Person ${this.personId} has returned home to ${borderCrossing?.home?.title} from ${borderCrossing?.zone?.title}`);

				} else {
					// Walk back to home zone
					// If for some reason there is no home zone walk to the border
					// Just keep walking if we are already in this zone
					const targetZoneTitle: string = borderCrossing?.home?.title || 'border';
					if(!this.cell.getProp(targetZoneTitle)) {
						const zone = this.plane.getZone(targetZoneTitle);
						const randX = zone.startX + Math.round(Math.random() * (zone.endX - zone.startX));
						const randY = zone.startY + Math.round(Math.random() * (zone.endY - zone.startY));
						const dest = this.plane.getCell(randX, randY);

						/*
						const border: Cell[] = this.plane.getCellsInZone(targetZone);
						const dest = border[Math.floor(Math.random() * border.length)];
						*/

                        if(dest) {
                            this.faceCell(dest);
                        }
					}
				}
			}

		}

		

		if(this.virus) {
			this.virus.run();
		}

		if(this.cell === startCell && this.status === PersonStatus.isolation) {
			// We don't need to redraw anything for an alive person in solution
			return;
		}


		// Redraw the sprite
		const personSpriteId: string = `${this.personId}_person`;
		const iconSpriteId: string = `${this.personId}_icon`;
		const markerSpriteId: string = `${this.personId}_marker`;
		this.plane.removeSprite(personSpriteId, {});
		this.plane.removeSprite(iconSpriteId, {});
		this.plane.removeSprite(markerSpriteId, {});

		const angle: number = this.heading.angle();
		const direction: Direction = Math.round(angle / (Math.PI / 4));

		let imageWidth = 20;
		const imageHeight = 20;

		if(this.status === PersonStatus.dead) {
			this.image = this.images.dead;
		} else {
			imageWidth = 10;
			switch(direction) {
			case Direction.N:
			case Direction.NE:
				this.image = this.images.ne;
				break;
			case Direction.E:
			case Direction.EE:
			case Direction.SE:
				this.image = this.images.se;
				break;
			case Direction.S:
			case Direction.SW:
				this.image = this.images.sw;
				break;
			case Direction.W:
			case Direction.NW:
				this.image = this.images.nw;
				break;
			default:
				this.image = this.images.ne;
			}
		}

		if(this.marker instanceof HTMLImageElement && this.cell) {
            this.plane.drawSprite(
                this.cell.x,
                this.cell.y,
                this.marker,
                {
                    id: markerSpriteId,
                    width: imageWidth,
                    height: imageHeight,
                    center: true,
                    persist: true
                    //layer: 'people'
                }
            );
		}

		if(this.image instanceof HTMLImageElement && this.cell) {
			this.plane.drawSprite(
				this.cell.x,
				this.cell.y,
				this.image,
				{
					id: personSpriteId,
					width: imageWidth,
					height: imageHeight,
					center: true,
					persist: true
//				layer: 'people'
				}
			);
		}

		if(this.icon instanceof HTMLImageElement && this.cell) {
			this.plane.drawSprite(
				this.cell.x,
				this.cell.y - 20,
				this.icon,
				{
					id: iconSpriteId,
					width: 20,
					height: 20,
					center: true,
					persist: true
//				layer: 'people'
				}
			);
		}


		if(this.status === PersonStatus.dead && this.cell) {
			this.plane.removeAgentFromCell(this, this.cell);
		}

        /*
		if(this.cell && this.cell.getAgents().length > 1) {
			log.info(`Cell [${this.cell.x},${this.cell.y}] has more than one agent`);
		}
		*/
	}


}

export default PersonAgent;
export {
	PersonAgent,
	PersonStatus
};
