

type Threshold = {
    maxDeaths?: number,
    points: number,
    costBonus?: number
};


// These must be ordered from lowest to highest in order for the find to work
export const scoreThresholds: Threshold[] = [
    {
        maxDeaths: 0.02,
        points: 4,
        costBonus: 500
    },
    {
        maxDeaths: 0.05,
        points: 3,
        costBonus: 500
    },
    {
        maxDeaths: 0.1,
        points: 2,
        costBonus: 500
    },
    {
        maxDeaths: 0.2,
        points: 1,
        costBonus: 500
    },
    {
        // 1 point is the lower achievable with no death limit but no cost bonus available
        points: 1
    }
];

export function calculateScore({population, deaths, cost} : {population: number, deaths: number, cost: number}): number {
    const deadPct = deaths / population; 

    // Get the first score where the dead percentage is less than the maximum threshold
    // If there is no threshold then it matches
    const scoreThreshold = scoreThresholds.find((threshold) => threshold.maxDeaths ? deadPct < threshold.maxDeaths : true);
    if(!scoreThreshold) {
        // If there is no catch-all threshold they get 0 points
        return 0;
    }
    let expensePoints = 0;
    if(scoreThreshold.costBonus) {
        expensePoints = cost <= scoreThreshold.costBonus ? 1 : 0;
    }
    return scoreThreshold.points + expensePoints;
}
