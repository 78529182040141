/* eslint no-console: 0 */
import {showSetupPages} from './controls/setup-pages.ts';
import browserUpdate from 'browser-update';
import styles from '../styles/style.scss';
//import {showInstructions} from './controls/instruction-dialog.js';

import supportsCSSTransformsOnSVG from './util/supports-css-transforms-on-svg';

// These polyfills drastically incease the build size
// They should be reviewed when IE11 support is no longer required
import '@babel/polyfill';
import 'whatwg-fetch';
import './custom-event-polyfill';


if(!supportsCSSTransformsOnSVG()) {
	document.querySelector('.controls').classList.remove('svg-supported');
}

browserUpdate({required: {e: 44}});

console.log('Start setup');
showSetupPages();
//showInstructions();



//plane.addEventListener('tick', generateGathering(rightZone));
