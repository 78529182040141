import type Agent from './agent';
import type Plane from './plane';
import type Dictionary from './interfaces/dictionary';

/**
 * A single cell in a plane. Hopefully the memory overhead of a class instance for each cell is not too great. 
 * Has the following properties
 * 	@property {number} type The type of terrain on this cell
 **/
class Cell {

    x: number;
    y: number;
    type: number; // This should be reviewed as it may be better used as a prop
    agents: Agent[];
    properties: Dictionary<unknown>;
    image: undefined | string | typeof Image;
    plane: Plane | undefined;




	constructor(x: number, y: number, type: number, properties?: Dictionary<string>) {
		this.x = x;
		this.y = y;
		this.type = type;
		this.agents = [];
		this.properties = properties || {};
		this.image = undefined;
	}

	getProp(property: string): unknown{
		return this.properties[property];
	}

	/**
	 * setProp
	 * Props are useful for complex data on cells but are slow when using large numbers of cells
	 * @param {string} property The property name to set
	 * @param {any} value The value to set on prop
	 * @return {undefined}
	 **/
	setProp(property: string, value: unknown): void {
		this.properties[property] = value;
		if(this.plane) {
			this.plane.tagCell(this, property);
		}
	}

	/**
	 * Attach an agent to this cell.
	 * This should only be called by the plane controlling the cell.
	 * To attach an agent to a cell use Plane.addAgentToCell
	 * @param {Agent} agent The agent to attach.
	 * @return {undefined} no return value
	 **/
	addAgent(agent: Agent): void {
		this.agents.push(agent);
	}

	/**
	 * Get all agents on this cell
	 * @return {Agent[]} An array of all agents attached to the cell
	 **/
	getAgents(): Agent[] {
		return this.agents;
	}

	/**
	 * Remvoe an agent from this cell.
	 * This should only be called by the plane controlling the cell.
	 * To attach an agent to a cell use Plane.removeAgentFromCell
	 * @return {undefined} no return value
	 **/
	removeAgent(agent: Agent): void {
		const index = this.agents.indexOf(agent);
		if(index >= 0) {
			this.agents.splice(index, 1);
		}
	}
}

export default Cell;
