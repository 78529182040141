import {SetupPlaneOptions} from '../controls/setup-controls';
import detailsTemplate from '../../views/game-details.pug';

const OPEN_CLASS = 'game-details--open';

export function setupDetailsSlider(parentNode: HTMLElement, settings: SetupPlaneOptions): HTMLElement | null {
    const tmpElem = document.createElement('div');
    tmpElem.innerHTML = detailsTemplate({
        healthSpendLabels: ['low', 'med low', 'med high', 'high'],
        ...settings
    });
    while(tmpElem.childNodes.length) {
        parentNode.appendChild(tmpElem.childNodes[0]);
    }

	const detailsElem = document.getElementById('game-details');
	if(!detailsElem) {
	    console.error('Error adding details element');
	    return null;
    }

    const toggleBtn = document.getElementById('game-details-slideup');
    if(toggleBtn) {
        toggleBtn.addEventListener('click', (evt: MouseEvent): any => {
            evt.preventDefault();
            if(detailsElem.classList.contains(OPEN_CLASS)) {
                detailsElem.classList.remove('game-details--open');
            } else {
                detailsElem.classList.add('game-details--open');
            }
        });
    }
	return detailsElem;
}

export function hideSlider(): void {
	const detailsElem = document.getElementById('game-details');
	if(detailsElem && detailsElem.classList.contains(OPEN_CLASS)) {
	    detailsElem.classList.remove(OPEN_CLASS);
    }
}

export function showSlider(): void {
	const detailsElem = document.getElementById('game-details');
	if(detailsElem && !detailsElem.classList.contains(OPEN_CLASS)) {
	    detailsElem.classList.add(OPEN_CLASS);
    }
}
