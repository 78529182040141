import getLogger, {Logger} from './logger';
import getEventCoords from './util/get-event-coords';
import {PlaneTarget} from './plane-target';
import type Dictionary from './interfaces/dictionary';
import type {Sprite, EventHandler} from './plane-target';


type PlaneCanvasLayer = {
    canvas: HTMLCanvasElement,
    order: number,
    context?: CanvasRenderingContext2D,
    reset?: boolean
};

// TODO: tidy up the event type
type CanvasEventHandler = (evt: MouseEvent | TouchEvent | {x: number, y: number}) => void;

type LayerConfig = {
    name: string
}

type PlaneCanvasOptions = {
    height?: number,
    width?: number,
    resizeParent?: boolean,
    layers?: LayerConfig[]
};

const defaultLayer = 'main';


/**
 * Manage canvas
 **/
class PlaneCanvas implements PlaneTarget {

    layers: Dictionary<PlaneCanvasLayer>;
    handlers: {
        click: CanvasEventHandler[],
        pan: CanvasEventHandler[],
        mousemove: CanvasEventHandler[]
    };
    container: HTMLElement;
    canvasStyle?: {
        width: number,
        height: number
    };

    cellsToDraw: Dictionary<Sprite>;
    spritesToDraw: Dictionary<Sprite>;
    cachedImages: Dictionary<string>; // Dictionary of DATA URIs
    scaleScheduled?: ReturnType<typeof setTimeout>;
    bounceScaleTime: number;
    resizeParent: boolean;
    topLayer?: string;

        


	constructor(container: HTMLElement, options: PlaneCanvasOptions = {}) {
		this.handlers = {
			click: [],
			pan: [],
			mousemove: []
		};
		this.cachedImages = {};
		this.bounceScaleTime = 200;
		this.resizeParent = options.resizeParent || false;

		this.canvasClicked = this.canvasClicked.bind(this);
		this.onMouseDown = this.onMouseDown.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
		this.onPan = this.onPan.bind(this);
		this.onMouseMove = this.onMouseMove.bind(this);
		this.addEventListener = this.addEventListener.bind(this);
		this.removeEventListener = this.removeEventListener.bind(this);

		this.drawCell = this.drawCell.bind(this);
		this.drawSprite = this.drawSprite.bind(this);
		this.removeSprite = this.removeSprite.bind(this);
		this.loadImage = this.loadImage.bind(this);
		this.drawCells = this.drawCells.bind(this);
		this.drawRect = this.drawRect.bind(this);
		this.clearRect = this.clearRect.bind(this);

		this.cellsToDraw = {};
		this.spritesToDraw = {};
		this.layers = {};

		this.container = container;

		this.setupLayers(container, options.layers);
		if(options.height) {
			this._height = options.height;
		}
		if(options.width) {
			this._width = options.width;
		}
	}

	/**
	 * Private function to set canvas width without redrawing
	 * @param {number} width The new width for the canvas
	 **/
	set _width(width: number) {
		for(const layer in this.layers) {
			if(Object.prototype.hasOwnProperty.call(this.layers, layer)) {
				this.layers[layer].canvas.width = width;
				this.layers[layer].canvas.style.width = width + 'px';
			}
		}
		if(this.resizeParent && this.container.parentNode) {
		    const parent = this.container.parentNode as HTMLElement;
			parent.style.width = width + 'px';
		}
	}

	/**
	 * Set the canvas width
	 * @param {number} width The new width for the canvas
	 **/
	set width(width: number) {
		if(this.width !== width) {
			for(const layer in this.layers) {
				if(Object.prototype.hasOwnProperty.call(this.layers, layer)) {
					this.cacheImage(layer);
					this.layers[layer].canvas.width = width;
					this.loadCachedImage(layer);
					this.clearCachedImage(layer);
					this.scheduleScale();
				}
			}
		}
	}

	/**
	 * Get the canvas width
	 **/
	get width() {
	    const layer = Object.values(this.layers).pop();
		return layer ? layer.canvas.width : 0;
	}

	/**
	 * Private function to set canvas height without redrawing
	 * @param {number} height The new height for the canvas
	 **/
	set _height(height: number) {
		for(const layer in this.layers) {
			if(Object.prototype.hasOwnProperty.call(this.layers, layer)) {
				this.layers[layer].canvas.height = height;
				this.layers[layer].canvas.style.height = height + 'px';
			}
		}
		if(this.resizeParent && this.container.parentNode) {
		    const parent = this.container.parentNode as HTMLElement;
			parent.style.height = height + 'px';
		}
	}

	/**
	 * Set the canvas height
	 * @param {number} height The new height for the canvas
	 **/
	set height(height: number) {
		if(this.height !== height) {
			for(const layer in this.layers) {
				if(Object.prototype.hasOwnProperty.call(this.layers, layer)) {
					this.cacheImage(layer);
					this.layers[layer].canvas.height = height;
					this.loadCachedImage(layer);
					this.clearCachedImage();
					this.scheduleScale();
				}
			}
		}
	}

	/**
	 * Get the canvas height
	 **/
	get height() {
	    const layer = Object.values(this.layers).pop();
		return layer ? layer.canvas.height: 0;
	}

	setupLayers(container: HTMLElement, layers: Dictionary<LayerConfig>) {
		this.container = container;

		this.layers = {
			background: {
				canvas: document.createElement('canvas'),
				order: 0
			},
			main: {
				canvas: document.createElement('canvas'),
				order: 1
			},
			// The sprite layer is a top layer that is cleared on each redraw allowing sprites to be drawn
			sprites: {
				canvas: document.createElement('canvas'),
				order: 10,
				reset: true
			}
		};

		if(Array.isArray(layers)) {
			layers.forEach((layer) => {
				if(typeof layer === 'object' && typeof layer.name === 'string') {
					this.layers[layer.name] = {
						order: 2,
						...layer,
						canvas: document.createElement('canvas')
					};
				} else {
					this.layers[layer] = {
						order: 2,
						canvas: document.createElement('canvas')
					};
				}
			});
		}

		this.topLayer = 'main';

		Object.keys(this.layers).forEach((layer) => {
			this.layers[layer].canvas.style.position = 'absolute';
			this.layers[layer].canvas.style.top = '0';
			this.layers[layer].canvas.style.left = '0';
			this.layers[layer].canvas.style.width = '100%';
			this.layers[layer].canvas.dataset.layer = layer;

			this.layers[layer].context = this.layers[layer].canvas.getContext('2d') || undefined;

			container.appendChild(this.layers[layer].canvas);

			if(this.layers[this.topLayer!].order <= this.layers[layer].order) {
				this.topLayer = layer;
			}
		});

        /* Removed: exposing an arbitary canvas and canvas is misleading
		// TODO: This feels wrong. Should we create a getter that defaults to the top layer without a key
		this.canvas = this.layers[this.topLayer!].canvas;
		this.context = this.layers[this.topLayer!].context;
		*/

		this.cellsToDraw = {};
		this.spritesToDraw = {};

		window.requestAnimationFrame(this.drawCells);

		// Save styles so we can reset back to them
		this.canvasStyle = {
			width: this.width,
			height: this.height
		};

		window.addEventListener('resize', this.scheduleScale.bind(this));
		this.scheduleScale();
	}

	/**
	 * Store the current image for later use
	 * @param {string} layer The layer to cache image from. Defaults to main
	 **/
	cacheImage(layer: string = defaultLayer) {
		this.cachedImages[layer] = this.layers[layer].canvas.toDataURL();
	}

	/**
	 * Load a previously cached image to the canvas
	 * @param {string} layer The layer to load cached image to. Defaults to main
	 * @return {Image|null} The loaded image or null if no image was loaded
	 **/
	loadCachedImage(layer: string = defaultLayer): HTMLImageElement | null {
		if(this.cachedImages[layer]) {
			const image = new Image();
			image.src = this.cachedImages[layer];
			image.onload = () => this.loadImage(image, {layer, scaleToCanvas: true});
			return image;
		}
		return null;
	}

	/**
	 * @param {string} layer The layer to clear from the cache. If not specified all cached images will be cleared
	 * Clear a previouly cached image
	 **/
	clearCachedImage(layer?: string) {
		if(!layer) {
			this.cachedImages = {};
		} else {
			delete this.cachedImages[layer];
		}
	}

	convertCoords(coords: {x: number, y: number}) {
        if(!this.topLayer) {
            throw new Error(`PlaneCanvas.concertCoords: The top layer is not yet defined`);
        }
        const canvas = this.layers[this.topLayer].canvas;
		const rect = canvas.getBoundingClientRect();

		const origX = coords.x - rect.left;
		const origY = coords.y - rect.top;

		const x = Math.floor(origX / (rect.width / this.width));
		const y = Math.floor(origY / (rect.height / this.height));

		return {x, y};
	}

	canvasClicked(evt: MouseEvent) {
		const coords = this.convertCoords({x: evt.clientX, y: evt.clientY});
		this.handlers.click.forEach((handler) => handler(coords));
	}

	onMouseDown(evt: MouseEvent | TouchEvent) {
		evt.preventDefault(); // touch events block mouse events to avoid firing twice
        if(!this.topLayer) {
            throw new Error(`PlaneCanvas.onMouseDown: The top layer is not yet defined`);
        }
		if(this.handlers.pan.length) {
			const canvas = this.layers[this.topLayer].canvas;
			canvas.addEventListener('mousemove', this.onPan);
			canvas.addEventListener('touchmove', this.onPan);
		}
	}

	onPan(evt: MouseEvent | TouchEvent) {
		evt.preventDefault(); // touch events block mouse events to avoid firing twice

		const evtCoords = getEventCoords(evt);
		const coords = this.convertCoords(evtCoords);
		this.handlers.pan.forEach((handler) => handler(coords));
	}

	onMouseUp(evt: MouseEvent | TouchEvent) {
		evt.preventDefault(); // touch events block mouse events to avoid firing twice
        if(!this.topLayer) {
            throw new Error(`PlaneCanvas.onMouseDown: The top layer is not yet defined`);
        }
		if(this.handlers.pan.length) {
			const canvas = this.layers[this.topLayer].canvas;
			canvas.removeEventListener('mousemove', this.onPan);
			canvas.removeEventListener('touchmove', this.onPan);
		}
	}

	onMouseMove(evt: MouseEvnet | TouchEvent) {
		const evtCoords = getEventCoords(evt);
		const coords = this.convertCoords(evtCoords);
		this.handlers.mousemove.forEach((handler) => handler(coords));
	}

	addEventListener(type: string, handler: EventHandler) {
        if(!this.topLayer) {
            throw new Error(`PlaneCanvas.onMouseDown: The top layer is not yet defined`);
        }
		const canvas = this.layers[this.topLayer].canvas;
		if(type === 'click') {
			if(!this.handlers.click.length) {
				canvas.addEventListener('click', this.canvasClicked);
			}
			this.handlers.click.push(handler);
		} else if(type === 'pan') {
			if(!this.handlers.pan.length) {
				canvas.addEventListener('mousedown', this.onMouseDown);
				canvas.addEventListener('touchstart', this.onMouseDown);
				canvas.addEventListener('mouseup', this.onMouseUp);
				canvas.addEventListener('touchend', this.onMouseUp);
			}
			this.handlers.pan.push(handler);
		} else if(type === 'mousemove') {
			if(!this.handlers.mousemove.lengt) {
				canvas.addEventListener('mousemove', this.onMouseMove);
			}
			this.handlers.mousemove.push(handler);
		}
	}

	removeEventListener(type, handler) {
		const canvas = this.layers[this.topLayer].canvas;
		if(type === 'click') {
			const index = this.handlers.click.indexOf(handler);
			if(index >= 0) {
				this.handlers.click.splice(index, 1);
				if(this.handlers.click.length === 0) {
					canvas.removeEventListener('click', this.canvasClicked);
				}
			}
		} else if(type === 'pan') {

			const index = this.handlers.pan.indexOf(handler);
			if(index >= 0) {
				this.handlers.pan.splice(index, 1);
				if(this.handlers.pan.length === 0) {
					canvas.removeEventListener('mousedown', this.onMouseDown);
					canvas.removeEventListener('touchstart', this.onMouseDown);
					canvas.removeEventListener('mouseup', this.onMouseUp);
					canvas.removeEventListener('touchend', this.onMouseUp);
				}
			}
		} else if(type === 'mousemove') {
			const index = this.handlers.mousemove.indexOf(handler);
			if(index >= 0) {
				this.handlers.mousemove.splice(index, 1);
				if(this.handlers.mousemove.length === 0) {
					canvas.removeEventListener('mousemove', this.onMouseMove);
				}
			}
		}
	}

	/**
	 * @param {Canvas} canvas The canvas to draw this plane to
	 * @deprecated
	 **/
	setCanvas() {
		throw new Error('Set canvas is deprecated');
	}

	/**
	 * Draw all pending cells
	 **/
	drawCells() {
		const layersToDraw = [...Object.keys(this.cellsToDraw), ...Object.keys(this.spritesToDraw)];
		layersToDraw
			.forEach((layer) => {
				if(this.layers[layer].reset) {
					this.clearLayer(layer);
				}
				this.drawSpritesOnLayer(layer);
				this.drawCellsOnLayer(layer)
			});
		this.cellsToDraw = {};
		window.requestAnimationFrame(this.drawCells);
	}

	/**
	 * Draw all pending cells on a layer
	 * @param {string} layer The layer to draw
	 **/
	drawCellsOnLayer(layer) {
		// Iterate through the cells to get dimensions to draw
		const cellsToDraw = this.cellsToDraw[layer];
		if(!cellsToDraw) {
			return;
		}
		const logger = getLogger();

		// Get largest cell X and Y and increment by one for width
		const width = parseInt(Object.keys(cellsToDraw).pop(), 10) + 1;
		const height = Object.keys(cellsToDraw)
			.map((xCoord) => parseInt(Object.keys(cellsToDraw[xCoord]).pop(), 10))
			.reduce(
				(acc, yCoord) => yCoord > acc ? yCoord : acc,
				0
			) + 1;

		if(width >= 0 && height >= 0) {

			const pixelSize = 4;
			const rowSize = width * pixelSize;


			const imageData = this.layers[layer].context.getImageData(0, 0, width, height);
			//const imageData = this.layers[layer].context.createImageData(width, height);

			for(let xCoord in cellsToDraw) {
				xCoord = parseInt(xCoord, 10);
				if(!cellsToDraw.hasOwnProperty(xCoord)) {
					return false;
				}

				for(let yCoord in cellsToDraw[xCoord]) {
					yCoord = parseInt(yCoord, 10);
					if(!cellsToDraw[xCoord].hasOwnProperty(yCoord)) {
						return false;
					}

					const rowOffset = yCoord * rowSize;
					const colOffset = xCoord * pixelSize;
					const pixelOffset = rowOffset + colOffset;

					const data = cellsToDraw[xCoord][yCoord];
					imageData.data[pixelOffset + 0] = data.red;
					imageData.data[pixelOffset + 1] = data.green;
					imageData.data[pixelOffset + 2] = data.blue;
					imageData.data[pixelOffset + 3] = data.alpha;
				}
			}

			this.layers[layer].context.putImageData(imageData, 0, 0);
		}
		this.cellsToDraw[layer] = {};
	}

	drawSpritesOnLayer(layer) {
		if(!this.spritesToDraw[layer]) {
			return;
		}
		this.spritesToDraw[layer] = this.spritesToDraw[layer]
			.filter((sprite) => {
				const {image, x, y} = sprite;
				const options = sprite.options || {};
				const {width, height, persist, center} = options;

				const targetX = center && width ? x - width/2 : x;
				const targetY = center && height ? y - height / 2 : y;
				this.layers[layer].context.drawImage(image, targetX, targetY, width, height);

				return persist;
			});
	}

	drawCell(x, y, red, green, blue, alpha = 255, options = {}) {
		const layer = options.layer || defaultLayer;
		if(x > this.width || x < 0 || y > this.height || y < 0) {
			throw new Error('Pixel out of bounds');
		}

		if(!this.cellsToDraw[layer]) {
			this.cellsToDraw[layer] = {};
		}

		if(!this.cellsToDraw[layer][x]) {
			this.cellsToDraw[layer][x] = {};
		}
		this.cellsToDraw[layer][x][y] = {x, y, red, green, blue, alpha};
		if(layer.reset) {
			layer.changed = true;
		}
	}

	drawRect(startX, startY, width, height, colour, layer = defaultLayer) {
		const context = this.layers[layer].context;

		const colourString = typeof colour === 'string'
			? colour
			: `rgba(${colour.red}, ${colour.blue}, ${colour.green}, ${colour.alpha ? colour.alpha / 255 : 1}`;

		context.fillStyle = colourString;
		context.fillRect(startX, startY, width, height);

	}

	clearRect(startX, startY, width, height, layer = defaultLayer) {
		const context = this.layers[layer].context;

		context.clearRect(startX, startY, width, height);

	}

	clearLayer(layer) {
		const {canvas} = this.layers[layer];
		//const { width, height } = canvas.getBoundingClientRect();
		const { width, height } = canvas;

		this.clearRect(0, 0, width, height, layer);
	}

	drawSprite(x, y, image, options) {
		const layer = options.layer || 'sprites';
		if(!this.spritesToDraw[layer]) {
			this.spritesToDraw[layer] = [];
		}
		this.spritesToDraw[layer].push({x, y, image, options});
		if(layer.reset) {
			layer.changed = true;
		}
	}

	/** 
	 * Remove a scheduled sprite
	 * This function is used to prevent a sprite from being drawn.
	 * It cannot remove an alredy drawn sprite.
	 * This is mainly useful to remote a persistant sprite
	 * or to  ensure an agent only draws one sprite per draw cycle
	 * as the agents may be processed faster than the page is rendered.
	 * @param {*} spriteId The identifier for this sprite passed in options.id when drawSprite was called
	 * @param {string} layer The layer to remove the sprite from. Defaults to the sprite layer
	 * @return {boolean} Boolean flag indicating if the sprite was removed or not
	 **/
	removeSprite(spriteId, options = {}) {
		const layer = options.layer || 'sprites';
		if(!this.spritesToDraw[layer]) {
			return;
		}
		const spriteIndex = this.spritesToDraw[layer].findIndex((sprite) => sprite.options.id === spriteId);
		if(spriteIndex < 0) {
			return false;
		}

		this.spritesToDraw[layer].splice(spriteIndex, 1);
		return true;
	}

	/**
	 * Load an image to this canvas
	 * @param {Image} image The image to draw
	 * @param {object} options Configuration options
	 * @param {string} options.layer The layer to draw the image on
	 * @param {boolean} options.fitCanvas Flag indicating if the canvas should be scaled to fit the image (default true)
	 **/
	loadImage(image, options = {}) {
		console.log("load image", image);
		const layer = options.layer || defaultLayer;
		const {fitCanvas, scaleToCanvas} = options;
		const x = options.x || 0;
		const y = options.y || 0;

		let {width, height} = image;
		console.log(`Image ${width}x${height}`);

		if(fitCanvas) {
			this._width = width;
			this._height = height;
		} else if(scaleToCanvas) {
			width = this.width;
			height = this.height;
		}

		window.requestAnimationFrame(() => {
			this.layers[layer].context.drawImage(image, x, y, width, height);
		});
	}

	scaleCanvas() {
		console.log('Scale canvas');
		const canvas = this.layers[defaultLayer].canvas;

		// Clear any existing styles before checking
		canvas.style.width = this.canvasStyle.width;
		canvas.style.height = this.canvasStyle.height;

		const ratio = canvas.width / canvas.height;
		const bounds = canvas.getBoundingClientRect();

		let currRatio = bounds.width / bounds.height;
		if(bounds.width === 0) {
			// Treat as too wide so it is sized from the height
			currRatio = ratio + 1;
		} else if(bounds.height === 0) {
			// Treat as too tall so it is sized from the width
			currRatio = ratio - 1;
		}

		const roundingFactor = 100;
		let width = `${bounds.width}px`;
		let height = `${bounds.height}px`;

		if(Math.round(currRatio * roundingFactor) !== Math.round(ratio * 100)) {
			if(currRatio > ratio) {
				// too wide
				for (let layer in this.layers) {
					const layerCanvas = this.layers[layer].canvas;
					layerCanvas.style.width = `${Math.round(bounds.height * ratio)}px`;
					width = layerCanvas.style.width;
				}
			} else {
				// too tall
				for (let layer in this.layers) {
					const layerCanvas = this.layers[layer].canvas;
					layerCanvas.style.height= `${Math.round(bounds.width / ratio)}px`;
					height = layerCanvas.style.height;
				}
			}
		}

		this.container.style.height = height;
		this.container.style.width = width;
		if(this.resizeParent) {
			this.container.parentNode.style.height = height;
			this.container.parentNode.style.width = width;
		}

		this.scaleScheduled = null;

		/*
		if(bounds.width != this.width) {
			const scale = bounds.width / this.width;
			if(bounds.height != this.height * scale) {
				this.canvas.style.height = `${Math.round(this.height * scale)}px`;
			}
		}
		*/
	}

	/**
	 * Schedule the canvas to check sizing.
	 * This will run run immediately but will occur after the specified delay unless another scale is scheduled in that time.
	 * If a check is already scheduled this function will do nothing
	 **/
	scheduleScale() {
		if(this.scaleScheduled) {
			return;
		}
		this.scaleScheduled = setTimeout(this.scaleCanvas.bind(this), this.bounceScaleTime);
	}
}

export default PlaneCanvas;
