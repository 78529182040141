import Agent from './agent';
import {PersonStatus} from './person_agent';
import { v4 as uuidv4 } from 'uuid';
import logger from 'loglevel';

const log = logger.getLogger('LocationAgent');
log.setLevel('WARN');

class LocationAgent extends Agent {
	image: Image;
	probability: number;
	capacity: number;
	duration: number;
	size: number[];
	locationId: string;
	isOpen: boolean;
	update: boolean;
	type: string;
	area: Cell[];

	static type: string = 'LocationAgent';

	/**
	 * Constructor
	 * @param {Plane} plane The plane this agent is running on
	 * @param {Cell} cell The cell this agent is attached to
	 * @param {object} config Configuration for this agent
	 **/
	constructor(plane: Plane, settings = {}) {
		super(plane, settings);
		this.type = LocationAgent.type;
		this.size = settings.size || [50, 50];
		this.area = [];

		if(settings.image instanceof HTMLImageElement) {
			this.image = settings.image;
		} else if(typeof settings.image === 'string') {
			const image: Image = new Image();
			image.onload = () => {
				this.image = image;
			};
			image.src = settings.image;
		}

		this.probability = settings.probability === undefined ? 0.5 : settings.probability; // Allow to override probability to 0
		this.capacity = settings.capacity || 10;
		this.duration = settings.duration;
		this.locationId = uuidv4();
		this.isOpen = true;
		this.update = true;
	}

	setCell(cell: Cell) {
		// Remove from previous location?
		if(this.size[0] && this.size[1]) {

			const startX = cell.x - this.size[0] / 2;
			const endX = cell.x + this.size[0] / 2;
			const startY = cell.y - this.size[1] / 2;
			const endY = cell.y + this.size[1] / 2;
			this.area = this.plane.getCellsInRange(startX, endX, startY, endY);
			this.area.forEach((cell) => cell.setProp('location', this));

			if(this.probability <= 0) {
				this.area.forEach((cell) => cell.setProp('closed', true));
			}
		} else {
			this.area = [];
		}
		return super.setCell(cell);
	}

	/**
	 * Entry point for this agent. Should be run on each tick.
	 * @return {undefined} no return value
	 **/
	run() {

		// See if any visitors are coming
		// If a location should not call people back then set probability to 0
		if(this.isOpen && Math.random() <= this.probability) {
			const attending: PersonAgent[] = [];
			const availableVisitors: Person[] = this.visitors.filter((visitor: Person) => visitor.status !== PersonStatus.isolation && !visitor.borderJumper);
			if(availableVisitors.length) {
				let gatheringSize: number = Math.floor(Math.random() * this.capacity);
				if(gatheringSize > availableVisitors.length) {
					// A quick sanity check if for some reason everyone is unavailable
					gatheringSize = this.visitors.length;
				}

				while(attending.length < gatheringSize) {
					const aPerson: Person = availableVisitors[Math.floor(Math.random() * availableVisitors.length)];
					if(attending.indexOf(aPerson) < 0) {
						attending.push(aPerson);
						aPerson.goToGathering(this);
						log.info(`Person ${aPerson.personId} is going to gatthering ${this.locationId}`);
					}
				}
			}
		}

		// If this location expires count down
		if(this.duration) {
			this.duration--;
		} else if(this.duration === 0) {
			if(this.visitors) {
				this.visitors.forEach((person) => {
					person.goingToGathering = null;
				});
			}
			this.plane.removeSprite(this.locationId);
			this.plane.removeAgentFromCell(this, this.cell);
		}

		if(this.update) {
			this.plane.removeSprite(this.locationId);

			if(this.image) {

				this.plane.drawSprite(
					this.cell.x,
					this.cell.y,
					this.image,
					{
						id: this.locationId,
						width: this.size[0],
						height: this.size[1],
						center: true,
						persist: true
					}
				);
			}
			this.update = false;
		}
	}


}

export default LocationAgent;
export {
	LocationAgent
};
