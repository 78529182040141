import Cell from './cell';
import './polyfills/flat';
import Dictionary from './interfaces/dictionary';
import Zone from './interfaces/zone';
import {Vector2} from 'three';
import type Agent from './agent';
import type {PlaneTarget, DrawSpriteOptions, RemoveSpriteOptions, DrawCellOptions, LoadImageOptions, Colour, EventHandler} from './plane-target';

const RECORD_PERFORMANCE = false;


const eventTypes: string[] = ['start', 'stop', 'tick'];

/**
* A 2D plane for fires to propogate across
**/
class Plane implements PlaneTarget {
		running: boolean;
		runSpeed: number;
		cells: Cell[][];
		drawTarget: PlaneTarget;
		agentRunOrder: string[];
        drawSprite: (x: number, y: number, image: HTMLImageElement, options: DrawSpriteOptions) => void;
        removeSprite: (id: string, options: RemoveSpriteOptions) => boolean;
        drawCell: (x: number, y: number, red: number, green: number, blue: number, alpha: number | undefined, options: DrawCellOptions) => void;
        drawRect: (startX: number, startY: number, width: number, height:number , colour: Colour | string, layer: string | undefined) => void;
        clearRect: (startX: number, startY: number, width: number, height: number, layer: string | undefined) => void;
        loadImage: (image: HTMLImageElement, options: LoadImageOptions) => void;
		activeAgents: Dictionary<Agent[]>;
		agentsToRemove: Dictionary<Agent[]>;
		taggedAgents: Dictionary<Agent[]>;
		taggedCells: Dictionary<Cell[]>;
		tick: number;
		events: Dictionary<EventHandler[]>;
		runningAgent: Agent | null;
		attributes: Dictionary<string>;
		zones: Dictionary<Zone>;


	/**
	 * @param {Cell[][]} cells A two dimensional array of cells that make up the plane
	 * @param {PlaneTarget} drawTarget The output to draw this plane on.
	 * @param {string[]} agentRunOrder a list of agent types in the order that they should be run
	 **/
	constructor(cells: Cell[][], drawTarget: PlaneTarget, agentRunOrder?: string[]) {
		this.running = false;
		this.runSpeed = 50;
		this.cells = cells;
		this.drawTarget = drawTarget;
		this.drawCell = drawTarget.drawCell;
		this.drawSprite = drawTarget.drawSprite;
		this.removeSprite = drawTarget.removeSprite;
		this.drawRect = drawTarget.drawRect;
		this.clearRect = drawTarget.clearRect;
		this.loadImage = drawTarget.loadImage;
		this.agentRunOrder = agentRunOrder;
		this.activeAgents = {};
		this.agentsToRemove = {};
		this.taggedAgents = {};
		this.taggedCells = {};
		this.tick = 0;
		this.events = {};
		this.runningAgent = null;
		this.attributes = {};
		this.zones = {};

		this.addPlaneToCells();
	}

	/**
	 * Attach this plane to each cell
	 * @return {undefined} no return value
	 **/
	addPlaneToCells(): undefined {
		this.cells.forEach(
			row => row.forEach(
				cell => { cell.plane = this; }
			)
		);
	}

	get width(): number {
		return this.cells.length;
	}

	get height(): number {
		return this.cells[0].length;
	}

	/**
	 * Start/resume running agents on this plane
	 * @return {undefined} no return value
	 **/
	start(): undefined {
		console.log('Start');
		this.running = true;
		const timer = setInterval(() => {
			if(!this.running) {
				clearInterval(timer);
				this.dispatchEvent({type: 'stop', target: this});
				return;
			}
			this.run();
		}, this.runSpeed);

		/*
		const runLoop = () => window.setTimeout(() => {
			if(this.running) {
				this.run();
				runLoop();
			} else {
				this.dispatchEvent({type: 'stop', target: this});
			}
		}, this.runSpeed);
		runLoop();
		*/

		this.dispatchEvent({type: 'start', target: this});
	}

	/**
	 * Stop/pause agents on this plane
	 * @return {undefined} no return value
	 **/
	stop(): undefined {
		this.running = false;
	}

	setAttribute(key: string, value: string): undefined {
		this.attributes[key] = value;
	}

	getAttribute(key: string): string {
		return this.attributes[key];
	}

	/**
	 * Generate a new 2D array of cells using data from a 2d array of vegetation types
	 * @param {Array.<number[]>} data A two dimensional array of vegetation types to load
	 * @return {undefined} no return value
	 **/
	loadData(data: number[][]): undefined {
		this.cells = data.map((col, x) => col.map((type, y) => new Cell(x, y, type, {})));
		this.activeAgents = {};
		this.agentsToRemove = {};
		this.addPlaneToCells();
		this.tick = 0;
		this.drawTarget.width = this.width;
		this.drawTarget.height = this.height;
	}

	/**
	 * Save an agent with the provided tag
	 * @param {Agent} agent The agent to tag
	 * @param {string} tag The tag to save against the agent
	 * @return {Agent} The tagged agent
	 **/
	tagAgent(agent: Agent, tag: string): Agent {
		if(!this.taggedAgents[tag]) {
			this.taggedAgents[tag] = [];
		}
		this.taggedAgents[tag].push(agent);
		if(agent.tags.indexOf(tag) < 0) {
			agent.tags.push(tag);
		}
		return agent;
	}

	/**
	 * Remove an agent for the tagged list
	 * @param {Agent} agent The agent to tag
	 * @param {string} tag The tag to remove from the agent
	 * @return {Agent} The tagged agent
	 **/
	untagAgent(agent: Agent, tag: string): Agent {

		const tagIndex = agent.tags.indexOf(tag);
		if(tagIndex >= 0) {
			agent.tags.splice(tagIndex, 1);
		}

		if(!this.taggedAgents[tag]) {
			// Return if the tag doesn't exist
			return agent;
		}

		const index = this.taggedAgents[tag].indexOf(agent);
		if(index >= 0) {
			this.taggedAgents[tag].splice(index, 1);
		}
		return agent;

	}

	/**
	 * Save a cell with the provided tag
	 * This is useful for small numbers of cells but is slow with larger numbers
	 * If many cells need to be stored in a group use zones instead
	 * @param {Cell} cell The cell to tag
	 * @param {string} tag The tag to save against the agent
	 * @return {Cell} The tagged cell 
	 **/
	tagCell(cell: Cell, tag: string): Cell{
		if(!this.taggedCells[tag]) {
			this.taggedCells[tag] = [];
		}
		if(this.taggedCells[tag].indexOf(cell) < 0) {
			this.taggedCells[tag].push(cell);
		}
		// This could be saved in the cell props but is not currently required
		return cell;
	}

	/**
	 * Remove an cell for the tagged list
	 * @param {Cell} cell The agent to tag
	 * @param {string} tag The tag to remove from the cell
	 * @return {Cell} The tagged cell
	 **/
	untagCell(cell: Cell, tag: string): Cell {

		if(!this.taggedCells[tag]) {
			// Return if the tag doesn't exist
			return agent;
		}

		const index = this.taggedCells[tag].indexOf(cell);
		if(index >= 0) {
			this.taggedCells[tag].splice(index, 1);
		}
		return cell;

	}

	/**
	* Zones: these are a more performant way to set an attribute on a cluster of cells. Only squares are currently supported
	**/

	addZone(zone: Zone) {
		if(this.zones[zone.title]) {
			throw new Error(`Zone ${zone.title} already exists`);
		}
		this.zones[zone.title] = zone;
	}

	getZone(title: string): Zone {
		return this.zones[title];
	}

	removeZone(title: string): Zone {
		const index = this.zones.findIndex((zone: Zone) => zone.title === title);
		if(index < 0) {
			return undefined;
		}
		const removed = this.zones[index];
		this.zones.splice(index, 1);
		return removed;
	}

	getZonesAtCoords(x: number, y: number): Zone[] {
		return Object.values(this.zones).filter((zone: Zone) => {
			return (
				zone.startX <= x
				&& zone.endX >= x
				&& zone.startY <= y
				&& zone.endY >= y
			);
		});
	}

	getCellsInZone(title: string): Cell[] {
		const zone = this.getZone(title);
		if(!zone) {
			return [];
		}
		return this.getCellsInRange(zone.startX, zone.endX, zone.startY, zone.endY);
	}

	cellHasZone(cell: Cell, title: string) {
		const zone = this.getZone(title);
		if(!zone) {
			return false;
		}
		return (
			zone.startX <= cell.x
			&& zone.endX >= cell.x
			&& zone.startY <= cell.y
			&& zone.endY >= cell.y
		);
	}



	/**
	* Get the cell at the provided coordinates
	* @param {number} x The x coodinate
	* @param {number} y The y coordinate
	* @return {Cell|null} The cell at the provided coordinates or null of the coordinates are invalid
	**/
	getCell(x: number, y: number): Cell {
		if(
			x >= this.cells.length
			|| x < 0
			|| y > this.cells[x].length
			|| y < 0
		) {
			return null;
		}
		return this.cells[x][y];
	}

	/**
	* Get a cell by following a direction vector from the provided coordinates
	* @param {number} srcX The source x coodinate
	* @param {number} srcY The source y coordinate
	* @param {Vector2} direction A direction vector describing the bearing and distance
	* @return {Cell|null} The cell at the provided coordinates or null of the coordinates are invalid
	**/
	getCellInDirection(srcX: number, srcY: number, direction: Vector2): Cell | null {
		return this.getCell(srcX + Math.round(direction.x), srcY + Math.round(direction.y));
	}

	/**
	* Get a direction vector from one cell to another.
	* Note that the returned Vector2 also contains distance.
	* For a direction only vector convert to a unit vector with vector.clampLength(1)
	* @param {Cell} srcCell The cell to get direction from
	* @param {Cell} destCell The cell to get direction to
	* @return {Vector2} direction A direction vector describing the bearing and distance
	*/
	getDirectionTo(srcCell: Cell, destCell: Cell): Vector2 {
		return new Vector2(destCell.x - srcCell.x, destCell.y - srcCell.y);
	}

	/**
	 * Get all cells in the coordinate range. All rangers are inclusive.
	 * The cells are ordered in columns. This means that 3,2, 3,3 and 3,4 will appear before 4,2.
	 * You can get any selected coordinate with the following equations
	 *	 x = Math.floor(index / (endY - startY + 1)) + startX
	 *	 y = index % (endY - startY + 1) + startY
	 * @param {number} startX the X coordinate to start gathering cells from
	 * @param {number} endX the X coordinate to stop gather cells from
	 * @param {number} startY the Y coordinate to start gatering cells from
	 * @param {number} endY The Y coordinate to stop gathering cells from
	 * @return {Cell[]} A single dimensional array of all cells in order.
	 **/
	getCellsInRange(startX: number, endX: number, startY: number, endY: number): Cell[] {
		const startXClean = startX >= 0 ? startX : 0;
		const startYClean  = startY >= 0 ? startY : 0;
		const filtered = this.cells
			.slice(startXClean, endX + 1)
			.flatMap((row) => row.slice(startYClean, endY + 1));
		return filtered;
	}

	getCellsWithTag(tag: string): Cell[] {
		return this.taggedCells[tag] || [];
	}

	/**
	* Get all cells adjacent to the given coordinates
	* @param {number} x The x coodinate
	* @param {number} y The y coordinate
	* @return {Cell[]} The cells adjacent to the provided coordinates
	**/
	getNeighbours(x: number, y: number): Cell {
		const minX = x > 0 ? x - 1 : x;
		const maxX = x < this.cells.length - 1 ? x + 1 : x;
		const minY = y > 0 ? y - 1 : y;
		const maxY = y < this.cells[x].length -1 ? y + 1 : y;

		//console.log(`Range x: ${minX}-${maxX}, y: ${minY}-${maxY}`);

		const neighbours = [];

		for(let targetX = minX; targetX <= maxX; targetX++) {
			for(let targetY = minY; targetY <= maxY; targetY++) {
				if(targetX !== x || targetY !== y) {
					neighbours.push(this.getCell(targetX, targetY));
				}
			}
		}

		return neighbours;
	}

	/**
	 * Add an agent to the given cell.
	 * It is important to use this function so the plane can track agents to run.
	 * @param {Agent} agent The agent to attach to the cell
	 * @param {Cell} cell The cell the agent should be attached to
	 * @return {undefined} no return value
	 **/
	addAgentToCell(agent: Agent, cell: Cell): undefined {
		if(!agent) {
			return;
		}
		if(!cell) {
			return;
		}
		cell.addAgent(agent);
		agent.setCell(cell);
		const agentType = agent.type;
		const type = Object.getPrototypeOf(agent).type;
		if(!this.activeAgents[agentType]) {
			this.activeAgents[agentType] = [];
		}
		this.activeAgents[agentType].push(agent);
	}

	/**
	 * Get agents at the provided coordinates
	 * @param {number} x The x coordinate of the cell to query
	 * @param {number} y The y coordinate of the cell to query
	 * @return {Agent[]} An array of all agents at these coordinates
	 **/
	getAgentAt(x, y) {
		return this.getCell(x, y).getAgents();
	}

	/**
	 * Remove an agent from a cell.
	 * It is important to use this function so the plane can stop tracking this agent
	 * @param {Agent} agent The agent to remove
	 * @param {Cell} cell The cell to remove an agent from
	 * @return {undefined} no return value
	 **/
	removeAgentFromCell(agent: Agent, cell: Cell) {
		cell.removeAgent(agent);

		// Make sure we have somewhere to put this agent
		const agentType = agent.type;
		if(!this.agentsToRemove[agentType]) {
			this.agentsToRemove[agentType] = [];
		}

		if(this.runningAgent && this.runningAgent.agent === agent) {
			// If this is the running agent save the index to try and save resources later
			if(this.activeAgents[this.runningAgent.type][this.runningAgent.index] === agent) {
				this.agentsToRemove[agentType].push(this.runningAgent);
				return;
			}
		}

		// If this agent is not running we need to search for it so just as it as is
		this.agentsToRemove[agentType].push({index: NaN, agent});
	}

	/**
	 * Get a list of all agents on the plane
	 * @return {Agent[]} An array of all agents running on this plane
	 **/
	getAllAgents(): Agent[] {
		return [].concat(...Object.values(this.activeAgents));
	}

	/**
	 * Remove all agents currently in the plane
	 **/
	removeAllAgents(): undefined {
		this
			.getAllAgents()
			.forEach((agent) => agent.cell.removeAgent(agent));
		this.activeAgents = {};
		this.agentsToRemove = {};
	}

	/**
	 * Get all agents on the plane of a given type
	 * @param {string} agentType The agent type (classname) to retrieve
	 * @return {Agent[]} An array of all the agents of this type running on the plane
	 **/
	getAgentsOfType(agentType: string): Agent[] {
		if(this.activeAgents[agentType]) {
			return this.activeAgents[agentType].slice(0);
		}
		return [];
	}

	/**
	 * Get all agents on the plane of a given type
	 * @param {string} tag The tag to fetch agents with
	 * @return {Agent[]} An array of all the agents with this tag running on the plane
	 **/
	getAgentsWithTag(tag: string): Agent[] {
		if(this.taggedAgents[tag]) {
			return this.taggedAgents[tag].slice(0);
		}
		return [];
	}

	/**
	 * Remove all old agents in a batch
	 * Benchmarking found removing all agents at once is significantly faster when removing many items if they don't have indexes
	 * @param {string} agentType the type of agent to be removed
	 * @return {undefined} no return value
	 **/
	removeOldAgentsBatch(agentType: string): undefined {
		const activeAgents = this.activeAgents[agentType];
		const agentsToRemove = this.agentsToRemove[agentType];

		activeAgents = activeAgents.filter((agent) => agentsToRemove.findIndex((toRemove) => toRemove.agent === agent) < 0);
		this.agentsToRemove[agentType] = [];

	}

	/**
	 * Remove all old agents individuall
	 * Benchmarking found removing all agents at once is up to two times faster when removing from small arrays
	 * @param {string} agentType the type of agent to be removed
	 * @return {undefined} no return value
	 **/
	removeOldAgentsIndividually(agentType: string): undefined {

		const activeAgents = this.activeAgents[agentType];
		const agentsToRemove = this.agentsToRemove[agentType];
		const rmCount = agentsToRemove.length;

		const removeList = agentsToRemove
			.sort((a, b) => {
				const result = a.index - b.index;
				return result === NaN
					? -1
					: result;
			});


		for(let i = rmCount - 1; i >= 0; i--) {
			const toRemove = removeList[i];

			if(toRemove.index !== NaN && activeAgents[toRemove.index] === toRemove.agent) {
				activeAgents.splice(toRemove.index, 1);
			} else {
				const index = activeAgents.indexOf(toRemove.agent);
				if(index >= 0) {
					this.activeAgents[agentType].splice(index, 1);
				}
			}
		}

		this.agentsToRemove[agentType] = [];
	}

	/**
	 * Cleanup old agents running on this plane.
	 * This must be run at the end of each tick.
	 * @return {undefined} no return value
	 **/
	removeOldAgents(): undefined {
		Object.keys(this.agentsToRemove).forEach((agentType) => {
			this.removeOldAgentsIndividually(agentType);
		});
	}

	/**
	 * Update the plane values and run all agents. Running agents are tracked in this.activeAgents
	 * This is the entry point to run each tick.
	 * @return {undefined} no return value
	 **/
	run(): undefined {
	    const startMark = 'game-loop-start';
	    if(RECORD_PERFORMANCE) {
            performance.mark(startMark);
        }

		this.tick++;

		// Run all agents in the order list
		if(this.agentRunOrder) {
			this.agentRunOrder.forEach((type) => {
			    const agentMark = `agent-${type}-runtime`;
			    if(RECORD_PERFORMANCE) {
			        performance.mark(agentMark);
                }
				this.getAgentsOfType(type).forEach((agent, index) => {
					this.runningAgent = {
						type,
						index,
						agent
					};
					agent.run();
					this.runningAgent = null;
				});

                if(RECORD_PERFORMANCE) {
                    const measureName = `agent-${type}-runtime-measure`;
                    performance.measure(measureName, agentMark);
                    performance.clearMarks(agentMark);
                }
			});
		}

		// Run any remaining agents
        const unsortedAgentMark = `agent-unsorted-runtime`;
        if(RECORD_PERFORMANCE) {
            performance.mark(unsortedAgentMark);
        }
		this.getAllAgents().forEach((agent, index) => {
			const type = agent.type
			// Only run them if we haven't already run this type
			if(!this.agentRunOrder || this.agentRunOrder.indexOf(type) < 0) {
				this.runningAgent = {
					type,
					index,
					agent
				};
				agent.run();
				this.runningAgent = null;
			}
		});
        if(RECORD_PERFORMANCE) {
            const measureName = `agent-unsorted-runtime-measure`;
            performance.measure(measureName, unsortedAgentMark);
            performance.clearMarks(unsortedAgentMark);
        }


		this.removeOldAgents();

		// Stop if there are no agents left to run
		if(!this.getAllAgents().length) {
			this.stop();
		}

		this.dispatchEvent({type: 'tick', target: this});

        if(RECORD_PERFORMANCE) {
            const measureName = 'gameLoopMeasure';
            performance.measure(measureName, startMark);
            const measures: PerformanceMeasure[] = performance.getEntriesByType('measure');
            const gameLoopMeasure = measures.find((measure) => measure.name === measureName);
            if(gameLoopMeasure && gameLoopMeasure.duration > 10) {
                console.warn(`Tick ${this.tick} of game loop took ${measures[0].duration} to complete`);
                measures.forEach((measure) => {
                    if(measure.name.startsWith('agent-')) {
                        console.warn(`${measure.name} took ${measure.duration}`);
                    }
                });
            }
            performance.clearMarks(startMark);
            performance.clearMeasures(measureName);
            // Clear out agent measures
            measures.forEach((measure) => {
                if(measure.name.startsWith('agent-')) {
                    performance.clearMeasures(measure.name);
                }
            });
        }

	}

	/**
	 * Add an event listener to the plane.
	 * Valid events for the plane are: "start", "stop" and "tick".
	 * Any other events will be passed to the draw target
	 * @param {string} eventType The type of event to listen for
	 * @param {function} handler The function to call when the event is triggered
	 * @return {undefined} no return value
	 **/
	addEventListener(eventType: string, handler: functino): undefined {
		if(eventTypes.indexOf(eventType) < 0) {
			this.drawTarget.addEventListener(eventType, handler);
			return;
		}

		if(!this.events[eventType]) {
			this.events[eventType] = [];
		}
		this.events[eventType].push(handler);
	}

	/**
	 * Remove an event listener to the plane.
	 * Valid events for the plane are: "start" and "stop".
	 * Any other events will be passed to the draw target
	 * @param {string} eventType The type of event to remove
	 * @param {function} handler The function currently bound to the event
	 * @return {undefined} no return value
	 **/
	removeEventListener(eventType: string, handler: EventHandler): undefined {
		if(eventTypes.indexOf(eventType) < 0) {
			this.drawTarget.removeEventListener(eventType, handler);
			return;
		}

		if(!this.events[eventType]) {
			return;
		}

		const eventIndex = this.events[eventType].indexOf(handler);
		if(eventIndex >= 0) {
			this.events[eventType].splice(eventIndex, 1);
		}
	}

	/**
	 * Dispatch an event from the plane
	 * @param {Event|object} evt The event or event-like object to trigger
	 * @param {string} evt.type The type of event being dispatched
	 * @return {undefined} no return value
	 **/
	dispatchEvent(evt: Event): void {
		const handlers = this.events[evt.type];
		if(handlers) {
			handlers.forEach((handler) => handler(evt));
		}
	}

}

export default Plane;
