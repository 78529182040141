import PlaneCanvas from './plane_canvas';
import Plane from './plane.ts';
import Cell from './cell';
import loadData from './controls/loadData';
import setupCounter from './counter';

function setupPlane(): Plane {
	const width = 628;
	const height = 400;


	const cells: Cell[][] = [];
	for(let x = 0; x < width; x++) {
		cells[x] = [];
		for(let y = 0; y < height; y++) {
			cells[x][y] = new Cell(x, y, 1, 1);
		}
	}

	const canvas = document.getElementById('canvas');
	const bounds = canvas.getBoundingClientRect();
	console.log('bounds', bounds);

	const canvasDrawer = new PlaneCanvas(canvas, {layers: ['people'], resizeParent: true, width: bounds.width, height: bounds.height});
	const plane = new Plane(cells, canvasDrawer, ['PersonAgent', 'LocationAgent']);

	loadData(plane);

	setupCounter(document.getElementById('tickCounter'), plane);
	return plane;
}

export default setupPlane;
