
import {PersonStatus, PersonAgent} from './person_agent';
import logger from 'loglevel';
import Virus from './virus';

const log = logger.getLogger('PersonAgent');
log.setLevel('WARN');

class GastroVirus extends Virus {

	constructor(host: PersonAgent) {
		super(host);
		console.log('Gastro');

		this.infectionStage = 300;

		if(host.age < 6) {
			this.mortalityRate = 20;
			this.infectionChance = 20;
		} else if(host.age < 65) {
			this.mortalityRate = 2;
			this.infectionChance = 8;
		} else {
			this.mortalityRate = 10;
			this.infectionChance = 5;
		}

		if(host.attributes) {
			if(host.attributes.improveWaterSanitation) {
				this.mortalityRate = this.mortalityRate / 4;
			}

			// Reduce mortality by spending on health services (similar to coronavirus)
			const healthSpend = parseInt(host.attributes.healthSpend, 10);
			log.debug({attributes: host.attributes, healthSpend, mortalityRate: this.mortalityRate, newRate: this.mortalityRate / healthSpend});
			if(healthSpend) {
				this.mortalityRate = this.mortalityRate / healthSpend;
			}
		}
	}

	static infect(host: PersonAgent): Virus {
		if(host?.icons?.gastro) {
			host.icons.infectious = host.icons.gastro;
		}
		return new GastroVirus(host);
	}

	infectNew(host: PersonAgent): Virus {
		return GastroVirus.infect(host);
	}
}

export default GastroVirus;
