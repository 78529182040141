import PersonAgent from '../person_agent.ts';
import {loadImages} from './load_images.ts';

const maxAge = 90;
const childAge = 15;

const statusIcons = {
	corona: 'images/corona-icon.png',
	gastro: 'images/gastro-icon.png',
	respiratory: 'images/respiratory-icon.png',
	isolation: 'images/quarantined.png'
};

const statusMarkers = {
	careful: 'images/marker-blue.png',
	infectious: 'images/marker-orange.png',
	isolation: 'images/marker-red.png',
	recovered: 'images/marker-purple.png'
};

const personImages = {
	man: {
		ne: 'images/man/man-ne.png',
		se: 'images/man/man-se.png',
		sw: 'images/man/man-sw.png',
		nw: 'images/man/man-nw.png',
		dead: 'images/dead.png'
	},
	woman1: {
		ne: 'images/woman1/woman1-ne.png',
		se: 'images/woman1/woman1-se.png',
		sw: 'images/woman1/woman1-sw.png',
		nw: 'images/woman1/woman1-nw.png',
		dead: 'images/dead.png'
	},
	boy: {
		ne: 'images/boy/boy-ne.png',
		se: 'images/boy/boy-se.png',
		sw: 'images/boy/boy-sw.png',
		nw: 'images/boy/boy-nw.png',
		dead: 'images/dead.png'
	}
};


async function spawnPeople(plane, count, zone = {}, attributes = {}, options = {}) {

	let imageSets = personImages;
	await Promise.all(Object.getOwnPropertyNames(personImages).map(async (imageProp) => {
		try {
			imageSets[imageProp] = await loadImages(personImages[imageProp]);
		} catch (err) {
			console.error(err);
			imageSets[imageProp] = personImages[imageProp];
		}
	}));

	const adultImages = [imageSets.man, imageSets.woman1];
	const childImages = [imageSets.boy];


	let icons = statusIcons;
	try {
		icons = await loadImages(icons);
		icons.infectious = icons.corona;
		console.log({icons});
	} catch (err) {
		console.error(err);
		icons = statusIcons;
	}

	let markers = statusMarkers;
	try {
		markers = await loadImages(markers);
	} catch (err) {
		console.error(err);
		markers = statusMarkers;
	}

	let people = [];
	for(let i = 0; i < count; i++) {
		const tags = zone?.title ? [zone.title] : [];
		const age = Math.floor(Math.random() * maxAge);
		let images = age <= childAge
			? childImages[Math.floor(Math.random() * childImages.length)]
			: adultImages[Math.floor(Math.random() * adultImages.length)];
		const person = new PersonAgent(plane, {images, icons, markers, tags, age, attributes});
		// This is different to the original method which selected that number of people - this is more efficient but could be converted
		if(options.borderCrossing) {
			if(Math.random() * 100 < options.borderCrossing.probability) {
				person.borderJumper = true;
				person.attributes.borderCrossing = options.borderCrossing;
			}
		}
		const x = Math.floor(Math.random() *  (zone.endX - zone.startX)) + zone.startX;
		const y = Math.floor(Math.random() * (zone.endY - zone.startY)) + zone.startY;
		const cell = plane.getCell(x, y);
		if(cell) {
			plane.addAgentToCell(person, cell);
		} else {
			console.error(`Cell out of range ${x},${y}`);
		}
	}
	return people;
}

export default spawnPeople;
export {
	spawnPeople
};
