import Zone from '../interfaces/zone';
import LocationAgent from '../location_agent';

interface GatheringOptions {
	percentChance: number,
	duration: number,
	capacity: number
};

const getGatheringGenerator = (zone: Zone, options: GatherintOptions = {}) => (evt: Event) => {
	const plane: Plane = evt.target;

	const percentChance: number = options.percentChance || 0.02;
	const duration: number = options.duration || 2;
	const capacity: number = options.capacity || 10;

	//if(Math.floor(Math.random() * 50) === 1 && plane.getAgentsOfType('LocationAgent').length === 0) {
	if(Math.random() <= percentChance) {
		console.log('Create gathering');
		const gathering: LocationAgent = new LocationAgent(plane, {duration, capacity, probability: 0, size: [0,0]});
		const x: number = Math.floor(Math.random() *  (zone.endX - zone.startX)) + zone.startX;
		const y: number = Math.floor(Math.random() * (zone.endY - zone.startY)) + zone.startY;
		const cell: Cell = plane.getCell(x, y);
		plane.addAgentToCell(gathering, cell);

		const people: PersonAgent[] = plane.getAgentsWithTag(zone.title).filter((agent) => !agent.hasTag('travelling'));
		let visitorCount: number = 0;
		const size: number = Math.floor(Math.random() * gathering.capacity);
		while(visitorCount < size) {
			const index: number = Math.floor(Math.random() * people.length);
			const person: PersonAgent = people[index];
			if(person.goingToGathering !== gathering) {
				person.goingToGathering = gathering;
				visitorCount++;
			}
		}

		gathering.visitors = people;

	}
}

export default getGatheringGenerator;
