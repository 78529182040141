import type Plane from '../plane';
import type Person from '../person_agent';

function makePeopleAware(plane: Plane, count: number, tag: string): Person[] {
	const people: Person[] = tag
		? plane.getAgentsWithTag(tag) as Person[]
		: plane.getAgentsOfType('PersonAgent') as Person[];
	const aware = [];
	while(aware.length < count) {
		const index = Math.floor(Math.random() * people.length);
		const person = people[index];
		if(!person.faceMask) {
			person.faceMask = true;
			aware.push(person);
		}
	}
	return aware;
}

export default makePeopleAware;
