import createPlane from '../setup-plane.ts';
import getZone from '../lib/zones.ts';
import generateGathering from '../lib/generate-gathering.ts';
import {spawnPeople} from '../lib/spawn-people';
import infectPeople from '../lib/infect-people';
import makePeopleAware from '../lib/make-people-aware.ts';
import Plane from '../Plane';
import Dictionary from '../interfaces/dictionary';
import LocationAgent from '../location_agent.ts';
import {loadImage} from '../lib/load_images.ts';
import CoronaVirus from '../coronavirus.ts';
import GastroVirus from '../gastrovirus.ts';
import RespiratoryVirus from '../respiratoryvirus.ts';
import calculateCost from '../util/calculate-cost.ts';
import checkForEndgame from '../lib/check-for-endgame.ts';
import {setupDetailsSlider} from '../util/details-slider.ts';

const selectors: Dictionary<string> = {
	t1People: '#t1people',
	t1VirusPct: '#t1viruspct',
	t1School: '#t1SchoolClosed',
	t1Resto: '#t1RestaurantClosed',
	t1Awareness: '#t1Awareness',
	t1FaceMasks: '#t1FaceMasks',
	t1Sanitation: '#t1Sanitation',
	t1HealthSpend: '#t1healthSpend',
	t1Testing: '#t1testing',
	t2People: '#t2people',
	t2VirusPct: '#t2viruspct',
	t2School: '#t2SchoolClosed',
	t2Resto: '#t2RestaurantClosed',
	t2Awareness: '#t2Awareness',
	t2FaceMasks: '#t2FaceMasks',
	t2Sanitation: '#t2Sanitation',
	t2HealthSpend: '#t2healthSpend',
	t2Testing: '#t2testing',
	xBorders: '#xborders',
	startBtn: '#startBtn',
	overlay: '#setupOverlay'
};

export interface SetupPlaneOptions {
	zone1People: number,
	zone2People: number,
	zone1Infected: number,
	zone2Infected: number,
	zone1School: boolean,
	zone2School: boolean,
	zone1Resto: boolean,
	zone2Resto: boolean,
	zone1FaceMasks: boolean,
	zone2FaceMasks: boolean,
	zone1Awareness: number,
	zone2Awareness: number,
	zone1Sanitation: boolean,
	zone2Sanitation: boolean,
	zone1HealthSpend: number,
	zone2HealthSpend: number,
	zone1Testing: number,
	zone2Testing: number,
	xBorders: number,
	virus: string
}

const cafeImage = 'images/cafe.png';
const schoolImage = 'images/school.png';
const hospitalImage = 'images/hospital.png';
const hospitalSmallImage = 'images/hospital_s.png';

async function setupPlane(plane: Plane, options: SetupPlaneOptions): Promise<Plane> {


	const leftZone: Zone = getZone('left', plane);
	const rightZone: Zone = getZone('right', plane);
	const borderZone: Zone = getZone('border', plane);
	plane.addZone(leftZone);
	plane.addZone(rightZone);
	plane.addZone(borderZone);

	// Flag zones
	/*
	const leftCells = plane.getCellsInRange(leftZone.startX, leftZone.endX, leftZone.endY - 4, leftZone.endY);
	leftCells.forEach((leftCell) => leftCell.setProp('left', true));
	plane.getCellsInRange(rightZone.startX, rightZone.endX, rightZone.startY, rightZone.startY + 4)
		.forEach((rightCell) => rightCell.setProp('right', true));
		*/
	// Create border
	plane.getCellsInRange(borderZone.startX, borderZone.endX, borderZone.startY, borderZone.endY)
		.forEach((borderCell) => borderCell.setProp('border', true));

	plane.setAttribute('travellers', options.xBorders * (options.zone1People + options.zone2People));

	const zone1Attributes = {
		healthSpend: options.zone1HealthSpend,
		testing: options.zone1Testing,
		sanitation: options.zone1Sanitation
	};
	const zone1Options = {
		borderCrossing: {
			probability: options.xBorders,
			zone: rightZone,
			home: leftZone,
			number: (options.zone1People + options.zone2People)
		}
	};
	const zone2Attributes = {
		healthSpend: options.zone2HealthSpend,
		testing: options.zone2Testing,
		sanitation: options.zone2Sanitation
	};
	const zone2Options = {
		borderCrossing: {
			probability: options.xBorders,
			zone: leftZone,
			home: rightZone,
			number: (options.zone1People + options.zone2People)
		}
	};

	// Zone attributes are shared across all people in zone so bundle into a single object to save memory
	await spawnPeople(plane, options.zone1People, leftZone, {zoneData: zone1Attributes}, zone1Options);
	await spawnPeople(plane, options.zone2People, rightZone, {zoneData: zone2Attributes}, zone2Options);
	let virus = null;
	switch(options.virus) {
		case 'gastro':
			virus = GastroVirus;
			break;
		case 'respiratory':
			virus = RespiratoryVirus;
			break;
		case 'corona':
		default:
			virus = CoronaVirus;
	}
	infectPeople(plane, options.zone1Infected, leftZone.title, virus);
	infectPeople(plane, options.zone2Infected, rightZone.title, virus);
	if(options.zone1FaceMasks && options.zone1Awareness > 0) {
		makePeopleAware(plane, options.zone1People / options.zone1Awareness, leftZone.title);
	}
	if(options.zone2FaceMasks && options.zone2Awareness > 0) {
		makePeopleAware(plane, options.zone2People / options.zone2Awareness, rightZone.title);
	}


	const leftCitizens =  plane.getAgentsWithTag(leftZone.title);
	const rightCitizens =  plane.getAgentsWithTag(rightZone.title);

	const cafeImagePromise = loadImage(cafeImage)
		.then((image) => {
			const t1Tag = `${leftZone.title}Restaurant`;
			const t1Restaurant : LocationAgent = new LocationAgent(plane, {image, size: [64,52], probability: options.zone1Resto ? 0 : 0.05, tags: [leftZone.title, t1Tag, 'restaurant']});
			t1Restaurant .visitors = leftCitizens;
			const cell1: Cell = plane.getCell(400, 550);
			plane.addAgentToCell(t1Restaurant, cell1);

			const t2Tag = `${rightZone.title}Restaurant`;
			const t2Restaurant: LocationAgent = new LocationAgent(plane, {image, size: [64,52], probability: options.zone2Resto ? 0 : 0.05, tags: [rightZone.title, t2Tag, 'restaurant']});
			t2Restaurant.visitors = rightCitizens;
			const cell2: Cell = plane.getCell(800, 250);
			plane.addAgentToCell(t2Restaurant, cell2);

		});

	const schoolImagePromise = loadImage(schoolImage)
		.then((image) => {
			const t1Tag = `${leftZone.title}School`;
			const t1School: LocationAcent = new LocationAgent(plane, {image, size: [170,120], probability: options.zone1School ? 0 : 0.05, tags: [t1Tag]});
			t1School.visitors = leftCitizens.filter((person) => person.age <= 15);
			const cell1: Cell = plane.getCell(100, 200);
			plane.addAgentToCell(t1School, cell1);

			const t2Tag = `${rightZone.title}School`;
			const t2School: LocationAgent = new LocationAgent(plane, {image, size: [170,120], probability: options.zone2Resto ? 0 : 0.05, tags: [t2Tag]});
			t2School.visitors = rightCitizens.filter((person) => person.age <= 15);;
			const cell2: Cell = plane.getCell(950, 600);
			plane.addAgentToCell(t2School, cell2);

		});

	const z1HospitalLocation = [400,150];
	const largeHospitalSize = [83,97];
	const smallHospitalSize = [83,64];

	let z1HImage = hospitalImage;
	let z1HSize = largeHospitalSize;
	if(options.zone1HealthSpend < 3) {
		z1HImage = hospitalSmallImage;
		z1HSize = smallHospitalSize;
	}
	const hospitalImagePromise1 = loadImage(z1HImage)
		.then((image) => {
			console.log({image});
			const t1Tag = `${leftZone.title}Hospital`;
			const t1Hospital: LocationAcent = new LocationAgent(plane, {image, size: z1HSize, probability: 0, tags: [t1Tag]});
			const cell: Cell = plane.getCell(400, 150);
			plane.addAgentToCell(t1Hospital, cell);
		});
	let z2HImage = hospitalImage;
	let z2HSize = largeHospitalSize;
	if(options.zone2HealthSpend < 3) {
		z2HImage = hospitalSmallImage;
		z2HSize = smallHospitalSize;
	}
	const hospitalImagePromise2 = loadImage(z2HImage)
		.then((image) => {
			const t2Tag = `${leftZone.title}Hospital`;
			const t2Hospital: LocationAcent = new LocationAgent(plane, {image, size: z2HSize, probability: 0, tags: [t2Tag]});
			const cell: Cell = plane.getCell(600, 550);
			plane.addAgentToCell(t2Hospital, cell);
		});
	plane.setAttribute('z1HealthSpend', options.zone1HealthSpend); // required?
	plane.setAttribute('z2HealthSpend', options.zone2HealthSpend); // required?
	/*
	plane.setAttribute('z1Cost', calculateCost({
		schoolsClosed: options.zone1School,
		restaurantsClosed: options.zone1Resto,
		amountOfTesting: options.zone1Testing,
		healthSpend: options.zone1HealthSpend
	});
	plane.setAttribute('z2Cost', calculateCost({
		schoolsClosed: options.zone2School,
		restaurantsClosed: options.zone2Resto,
		amountOfTesting: options.zone2Testing,
		healthSpend: options.zone2HealthSpend
	});
	*/
	plane.setAttribute('setupOptions', JSON.stringify(options));

	try {
		await Promise.all([cafeImagePromise, schoolImagePromise, hospitalImagePromise1, hospitalImagePromise2]);
	} catch (err: unknown) {
		console.error('Error loading locations', err);
	}

	plane.addEventListener('tick', generateGathering(leftZone));
	plane.addEventListener('tick', checkForEndgame);
	return plane;
}

function setupRangeWatchers() {
	const watchers = document.querySelectorAll('[data-for-input]') as NodeListOf<HTMLElement>;
	for(const watcher of watchers) {
		const targetId = watcher.dataset.forInput;
		if(targetId) { 
            const watchTarget: HTMLInputElement | null = document.getElementById(targetId) as HTMLInputElement;
            if(watchTarget) {
                const suffix = watcher.dataset.suffix || '';
                let labels: Record<string,string>|null = null;
                if(watcher.dataset.inputLabels) {
                    try {
                        labels = JSON.parse(watcher.dataset.inputLabels);
                    } catch (err) {
                        console.error(`Invalid input label JSON string: ${watcher.dataset.inputLabels}`);
                    }
                }

                let updateHandler = () => watcher.innerText = `${watchTarget.value}${suffix}`;
                if(labels && watchTarget && labels[watchTarget.value]) {
                    updateHandler = () => watcher.innerText = `${labels[watchTarget.value]}${suffix}`;
                }

                watchTarget.addEventListener('change', updateHandler);
                watchTarget.addEventListener('input', updateHandler);
                watcher.innerText = updateHandler();
            }
        }
	}
}

/**
 * Setup controls for the plane
 * Binds buttons and inputs for user interaction
 * @return {undefined} no return value
 **/
function setupControls(virus: string): Plane {

	const t1PeopleInput: HTMLInputElement | null = document.querySelector(selectors.t1People);
	const t2PeopleInput: HTMLInputElement | null = document.querySelector(selectors.t2People);
	const t1VirusPctInput: HTMLInputElement | null = document.querySelector(selectors.t1VirusPct);
	const t2VirusPctInput: HTMLInputElement | null = document.querySelector(selectors.t2VirusPct);
	const t1SchoolInput: HTMLInputElement | null = document.querySelector(selectors.t1School);
	const t2SchoolInput: HTMLInputElement | null = document.querySelector(selectors.t2School);
	const t1RestoInput: HTMLInputElement | null = document.querySelector(selectors.t1Resto);
	const t2RestoInput: HTMLInputElement | null = document.querySelector(selectors.t2Resto);
    const t1AwarenessInput: HTMLInputElement | null = document.querySelector(selectors.t1Awareness);
	const t2AwarenessInput: HTMLInputElement | null = document.querySelector(selectors.t2Awareness);
	const t1FaceMasksInput: HTMLInputElement | null = document.querySelector(selectors.t1FaceMasks);
	const t2FaceMasksInput: HTMLInputElement | null = document.querySelector(selectors.t2FaceMasks);
	const t1SanitationInput: HTMLInputElement | null = document.querySelector(selectors.t1Sanitation);
	const t2SanitationInput: HTMLInputElement | null = document.querySelector(selectors.t2Sanitation);
	const t1HealthSpendInput: HTMLInputElement | null = document.querySelector(selectors.t1HealthSpend);
	const t2HealthSpendInput: HTMLInputElement | null = document.querySelector(selectors.t2HealthSpend);
	const t1TestingInput: HTMLInputElement | null = document.querySelector(selectors.t1Testing);
	const t2TestingInput: HTMLInputElement | null = document.querySelector(selectors.t2Testing);
	const xBorderInput: HTMLInputElement | null = document.querySelector(selectors.xBorders);

	const safeSetInt = (elem: HTMLInputElement | null, value: number): void => {
	    if(elem) {
	        elem.value = '' + value;
        }
    };
	const safeSetBool = (elem: HTMLInputElement | null, value: boolean): void => {
	    if(elem) {
	        elem.checked = value;
        }
    };
    safeSetInt(t1PeopleInput, 100);
    safeSetInt(t2PeopleInput, 20);
	safeSetInt(t1VirusPctInput, 20);
	safeSetInt(t1VirusPctInput, 20);
	safeSetInt(t1AwarenessInput, 20);
	safeSetInt(t2AwarenessInput, 65);
	safeSetBool(t1SchoolInput, false);
	safeSetBool(t2SchoolInput, false);
	safeSetBool(t1RestoInput, false);
	safeSetBool(t2RestoInput, true);
	safeSetBool(t1FaceMasksInput, false);
	safeSetBool(t2FaceMasksInput, false);
	safeSetBool(t1SanitationInput, false);
	safeSetBool(t2SanitationInput, true);
	safeSetInt(t1HealthSpendInput, 1);
	safeSetInt(t2HealthSpendInput, 2);
	safeSetInt(t1TestingInput, 20);
	safeSetInt(t2TestingInput, 60);
	safeSetInt(xBorderInput, 20);

	const plane = createPlane();

	const safeGetInt = (elem: HTMLInputElement | null): number => elem ? parseInt(elem.value, 10) : 0;

	const startBtn = document.querySelector(selectors.startBtn);
	startBtn?.addEventListener('click', async (evt: Event) => {
		evt.preventDefault();

		const settings: SetupPlaneOptions = {
			zone1People: safeGetInt(t1PeopleInput),
			zone2People: safeGetInt(t2PeopleInput),
			zone1Infected: safeGetInt(t1PeopleInput) * (safeGetInt(t1VirusPctInput) / 100),
			zone2Infected: safeGetInt(t2PeopleInput) * (safeGetInt(t2VirusPctInput) / 100),
            zone1Awareness: safeGetInt(t1PeopleInput) * (safeGetInt(t1AwarenessInput) / 100),
            zone2Awareness: safeGetInt(t2PeopleInput) * (safeGetInt(t2AwarenessInput) / 100),
			zone1School: !!t1SchoolInput?.checked,
			zone2School: !!t2SchoolInput?.checked,
			zone1Resto: !!t1RestoInput?.checked,
			zone2Resto: !!t2RestoInput?.checked,
			zone1HealthSpend: safeGetInt(t1HealthSpendInput),
			zone2HealthSpend: safeGetInt(t2HealthSpendInput),
            zone1FaceMasks: !!t1FaceMasksInput?.checked,
            zone2FaceMasks: !!t2FaceMasksInput?.checked,
            zone1Sanitation: !!t1SanitationInput?.checked,
            zone2Sanitation: !!t2SanitationInput?.checked,
			zone1Testing: safeGetInt(t1TestingInput),
			zone2Testing: safeGetInt(t2TestingInput),
			xBorders: safeGetInt(xBorderInput),
			virus
		};

		console.log(settings);


		await setupPlane(plane, settings);

		const overlay = document.querySelector(selectors.overlay);
		if(overlay) {
		    const parent = overlay.parentNode;
			parent?.removeChild(overlay);
			setupDetailsSlider(parent, settings);
		}

		plane.start();
	});
	setupRangeWatchers();
	return plane;
}

export default setupControls;
