
type EventCoords = {
    x: number,
    y: number
};

/**
 * Get coordinates from a mouse or touch event
 * @param {MouseEvent|TouchEvent} evt The event to fetch coordinates from
 * @return {Object} An object containing an x and y property with the extracted coordinates
 **/
function getEventCoords(evt: MouseEvent|TouchEvent): EventCoords {
    if('clientX' in evt && 'clientY' in evt) {
		return {x: evt.clientX, y: evt.clientY};
	}

    if('changedTouches' in evt && evt.changedTouches.length) {
		// Just use the first
		const touch = evt.changedTouches[0];
		return {x: touch.clientX, y: touch.clientY};
	}

	if(evt.touches) {
		const touch = evt.touches[0];
		return {x: touch.clientX, y: touch.clientY};
	}

	return {x: NaN, y: NaN};
}

export default getEventCoords;
