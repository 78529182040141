function infectPeople(plane, infectionSize, tag, Virus) {
	const people = tag
		? plane.getAgentsWithTag(tag)
		: plane.getAgentsOfType('PersonAgent');
	const infected = [];
	let count = 0;
	while(infected.length < infectionSize) {
		const index = Math.floor(Math.random() * people.length);
		const person = people[index];
		if(!person.virus) {
			Virus.infect(person);
			infected.push(person);
		}
	}
	return infected;
}

export default infectPeople;
