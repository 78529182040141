import {PersonStatus, PersonAgent} from './person_agent';
import logger from 'loglevel';
import Virus from './virus';

const log = logger.getLogger('PersonAgent');
log.setLevel('WARN');

class RespiratoryVirus extends Virus {

	constructor(host: PersonAgent) {
		super(host);

		console.log('Respiratory');

		this.infectionStage = 600 + Math.round(Math.random() * 200);

		this.infectionChance = 4;
		if(host.age < 10) {
			this.mortalityRate = 1;
		} else if(host.age < 65) {
			this.mortalityRate = 2;
		} else {
			this.mortalityRate = 20;
		}

		if(host.faceMask) {
			this.infectionChance = this.infectionChance / 3;
		}

	}


	static infect(host: PersonAgent): Virus {
		if(host?.icons?.respiratory) {
			host.icons.infectious = host.icons.respiratory;
		}
		return new RespiratoryVirus(host);
	}

	infectNew(host: PersonAgent): Virus {
		return RespiratoryVirus.infect(host);
	}
}

export default RespiratoryVirus;
